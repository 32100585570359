import ImageIcon from '@mui/icons-material/Image';

const ImageElement = {
  type: "image",
  name: "Image",
  icon: <ImageIcon/>,
  properties: [
    {
      name: "Image",
      content: [
        {
          id: "image-url",
          label: "Image URL",
          type: "input",
        },
        {
          id: "image-link",
          label: "Image link (optional)",
          type: "input",
        },
        {
          id: "background-color",
          label: "Background color",
          type: "colorPicker"
        },
        {
          id: "image-align",
          label: "Image Align",
          type: "toggleAlignment",
        },
        {
          id: "fixed-width",
          label: "Fixed width",
          type: "checkbox",
        },
        {
          id: "image-width",
          label: "Width",
          type: "conditionalToggle",
          conditionField: "fixed-width",
          options: {
            min: 1,
            max: 100
          }
        },
        {
          id: "fixed-height",
          label: "Fixed height",
          type: "checkbox",
        },
        {
          id: "image-height",
          label: "Height",
          type: "conditionalToggle",
          conditionField: "fixed-height",
          options: {
            min: 20,
            max: 350
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
  ],
  values: {
    "image-url": "https://img.logoipsum.com/236.svg",
    "image-link": "",
    "background-color": "#ffffff",
    "image-align": "center",
    "fixed-width": false,
    "image-width": 30,
    "fixed-height": false,
    "image-height": 80,
    "spacing-size": 10
  },
};

export default ImageElement;
