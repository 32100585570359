import EventIcon from "@mui/icons-material/Event";
import { format } from 'date-fns';

const OrderNumberAndDate = {
	type: "order-number-and-date",
	name: "Order Number And Date",
	icon: <EventIcon/>,
	properties: [
		{
			name: "Order Number and Date",
			content: [
				{
					id: "order-number",
					label: "Text",
					type: "input",
				},
				{
					id: "date-format",
					label: "Date format",
					type: "select",
					options: {
						'#M-#D-#Y (#G:#I#a)': format(new Date(), 'MM-dd-yyyy (hh:mma)'),
						'#D-#M-#Y (#G:#I#a)': format(new Date(), 'dd-MM-yyyy (hh:mma)'),
						'#Y-#M-#D (#G:#I#a)': format(new Date(), 'yyyy-MM-dd (hh:mma)'),
						'#M/#D/#Y (#G:#I#a)': format(new Date(), 'MM/dd/yyyy (hh:mma)'),
						'#D/#M/#Y (#G:#I#a)': format(new Date(), 'dd/MM/yyyy (hh:mma)'),
						'#Y/#M/#D (#G:#I#a)': format(new Date(), 'yyyy/MM/dd (hh:mma)'),
						'#M.#D.#Y (#G:#I#a)': format(new Date(), 'MM.dd.yyyy (hh:mma)'),
						'#D.#M.#Y (#G:#I#a)': format(new Date(), 'dd.MM.yyyy (hh:mma)'),
						'#Y.#M.#D (#G:#I#a)': format(new Date(), 'yyyy.MM.dd (hh:mma)'),
					}
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		},
	],
	values: {
		"order-number": "Order No.",
		"date-format": '#D/#M/#Y (#G:#I#a)',
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default OrderNumberAndDate;
