import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import clsx from "clsx";
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {Button, Paper} from "@mui/material";
import ThemeStyleHeader from "./ThemeStyleHeader";
import ElementOptionsHeader from "./ElementOptionsHeader";
import ThemeSettingsHeader from "./ThemeSettingsHeader";
import ElementPickerHeader from "./ElementPickerHeader";
import Slide from "@mui/material/Slide";
import { ListSubheader } from "@material-ui/core";
import availableTemplates from "../../../themeData/availableTemplates";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import {getUserSettings, saveGlobalElement} from "../../../utilities";

function Header({
  onTabChange,
  isMobilePreview,
  currentTemplate,
  setCurrentTemplate,
  setIsThemeStyleOpen,
  isThemeStyleOpen,
  setThemeSettings,
  isElementEditorOpen,
  setIsElementEditorOpen,
  currentElement,
  isThemeSettingsOpen,
  setIsThemeSettingsOpen,
  themeSettings,
  isElementPickerOpen,
  setIsElementPickerOpen,
  currentElements,
  currentTheme,
  currentStyle,
  currentThemeName,
  currentStyleName,
  themeJson,
  setThemeJson,
  userData,
  setUserData,
  reloadHeaderAndFooter,
  tabValue,
  setTabValue,
  isTrial,
}) {
  const [ isEmailPreviewTextPopupActive, setIsEmailPreviewTextPopupActive ] = useState(false);
  const [ values, setValues ]                                     = useState(null);
  const [ scrollPosition, setScrollPosition] = useState(0);
  let iframe = document.getElementById('previewIframe');

  // Load theme settings on any template load.
  useEffect(() => {
    if ( themeJson === false ) {
      fetch('/themes/' + currentTheme + '/theme.json')
          .then((r) => r.json())
          .then(settings => {
            setThemeJson(settings);
          })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ themeJson ]);


  // todo for sometime in future - put this into useEffect.
  if ( values === null ) {
    if (themeJson.templates){
      let valuesTmp = {};
      Object.values(themeJson.templates.default).forEach(el => {
        if ( el.type === 'header' ) {
          valuesTmp = el.values;
        }
      })

      let userSettings = userData;
      if ( typeof userSettings[ 'header' ] !== 'undefined' ) {
        Object.keys(userSettings[ 'header' ]).forEach(settingName => {
          valuesTmp[ settingName ] = userSettings[ 'header' ][ settingName ];
        })

        setValues(valuesTmp);
        return;
      } else {
        setValues(valuesTmp);
        return;
      }
    }
  }

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event) => {
    // noinspection JSUnresolvedFunction
    let valuesTmp                = window.structuredClone(userData['header']);

    if ( typeof event.target.id === 'undefined' ) {
      valuesTmp[ event.target.name ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    } else {
      valuesTmp[ event.target.id ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    }

    setValues( valuesTmp );

    saveGlobalElement('header', valuesTmp, userData).then(()=>{
      getUserSettings().then(res => setUserData(res));
    });
    reloadHeaderAndFooter();
    // Scroll back
    iframe.addEventListener("load", function () {
      iframe.contentWindow.document.documentElement.scrollTo({
        top: scrollPosition,
      });
    });
  }

  const handlePopupClick = (value) => {
    const iframeWindow = iframe.contentWindow;
    setScrollPosition(iframeWindow.scrollY);

    setIsEmailPreviewTextPopupActive(value);
  }

  return (
    <div id="header">
      {isThemeStyleOpen &&
        <ThemeStyleHeader
          setIsThemeStyleOpen={setIsThemeStyleOpen}
          setThemeSettings={setThemeSettings}
        />
      }
      {isElementEditorOpen &&
        <ElementOptionsHeader
          currentElement={currentElement}
          setIsElementEditorOpen={setIsElementEditorOpen}
          setIsThemeStyleOpen={setIsThemeStyleOpen}
          setThemeSettings={setThemeSettings}
          isElementEditorOpen={isElementEditorOpen}
        />
      }
      {isThemeSettingsOpen &&
        <ThemeSettingsHeader
          setIsThemeSettingsOpen={setIsThemeSettingsOpen}
          themeSettings={themeSettings}
        />
      }
      {isElementPickerOpen &&
        <ElementPickerHeader
          setIsElementPickerOpen={setIsElementPickerOpen}
          isElementPickerOpen={isElementPickerOpen}
        />
      }

      {! isThemeStyleOpen && ! isElementEditorOpen && ! isThemeSettingsOpen && ! isElementPickerOpen &&
        <Slide
          in={! isThemeStyleOpen && ! isElementEditorOpen && ! isThemeSettingsOpen && ! isElementPickerOpen}
          direction="left"
          style={{
            transform: "translate(100%)",
            transition: "transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          }}
        >
          <div className="theme">
            <div className="themeName">
              <img
                alt="Perceptiv Digital Logo"
                src="/images/outsmart-logo-white-orange.png"
                width={150}
              />
            </div>
          </div>
        </Slide>
      }

      {isEmailPreviewTextPopupActive &&
      <Fade
          in={isEmailPreviewTextPopupActive}
          timeout={600}
          style={{ transitionDelay:'100ms'}}
      >
        <div className="popupWrapper" onClick={e=>{
          if (e.target.classList.contains('popupWrapper')){
            handlePopupClick(false);
          }
          e.stopPropagation();
          return false;
        }}>
          <Paper className="popup">
            <div className="popupElementsWrapper">
              <div className="popupHeading">
                <h2 style={{marginTop: 0}}>Email Preview text</h2>
                <CloseIcon
                    onClick={() => handlePopupClick(false)}
                    className="closeButton"
                />
              </div>
              <div className="popupBody">
                <div className="settingsDescription">
                  <p>
                    A brief description of what's in your email, shown as a preview in the inbox of most major email clients next to the subject line.
                  </p>
                </div>
                <TextField
                    // label="Email Preview Text"
                    id="global-email-preview-text"
                    defaultValue={typeof values[ 'global-email-preview-text' ] === 'undefined' ? '' : values[ 'global-email-preview-text' ]}
                    onChange={handleChange}
                    variant="standard"
                    style={{
                      width: "70%",
                      alignSelf: "center",
                      textAlignLast: "center"
                    }}
                />
              </div>
              <div className="popupFooter">
                <Button
                    style={{
                      color: '#3b3b3b',
                      border: '1px solid #9b9b9b',
                      margin: "10px 5px",
                    }}
                    onClick={() => handlePopupClick(false)}
                    variant="outlined"
                    size="small"
                >
                  Close
                </Button>
                <Button
                    style={{
                      border: '1px solid #9b9b9b',
                      margin: "10px 5px",
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handlePopupClick(false);
                      let html                = '';
                      let currentTemplateName = '';
                      availableTemplates.forEach(template => {
                        if ( template.id === currentTemplate ) {
                          currentTemplateName = template.value;
                        }
                      });

                      let loadState = {
                        'header': false,
                        'footer': false,
                      };

                      currentElements.forEach((element, i) => {
                        loadState[i + element.id] = false;
                      })

                      let globalStyles = {};

                      let header = null;
                      let footer = null;

                      fetch('/themes/' + currentTheme + '/header.html')
                          .then((r) => r.text())
                          .then(text => {
                            loadState.header = true;

                            // Load default values for header and footer.
                            let allUserSettings = {};
                            Object.values(themeJson.templates.default).forEach(el => {
                              if ( el.type === 'header' ) {
                                allUserSettings[ 'header' ] = el.values;
                              }
                            })

                            // Override default values with user defined values.
                            let allUserSettingsTmp = userData;
                            if ( typeof allUserSettingsTmp[ 'header' ] !== 'undefined' ) {
                              Object.keys(allUserSettingsTmp[ 'header' ]).forEach(key => {
                                allUserSettings[ 'header' ][ key ] = allUserSettingsTmp[ 'header' ][ key ];
                              })
                            }

                            if ( typeof allUserSettings[ 'header' ] !== 'undefined' ) {

                              let defaultElement = themeJson.templates['default'].find(e => e.type === "header");
                              // Replace boolean values.
                              Object.keys(defaultElement.values).forEach(settingName => {
                              // eslint-disable-next-line no-useless-escape
                                let matches = text.match(new RegExp("{/header." + settingName + "}(\[\\S\\s\]*?){header." + settingName + "/}", "m"));
                                if ( matches !== null ) {
                                // eslint-disable-next-line no-useless-escape
                                  text = text.replaceAll(new RegExp("{/header." + settingName + "}(\[\\S\\s\]*?){header." + settingName + "/}", "gm"), (typeof allUserSettings[ 'header' ][ settingName ]  !== 'undefined' ? allUserSettings[ 'header' ][ settingName ] : defaultElement.values[settingName]) ? matches[ 1 ] : '');
                                }
                              })


                              // Replace regular values.
                              Object.keys(defaultElement.values).forEach(settingName => {
                                if (typeof allUserSettings['header'][settingName] !== 'undefined'){
                                  text = text.replaceAll('{header.' + settingName + '}', typeof allUserSettings[ 'header' ][ settingName ] === 'string' ? allUserSettings[ 'header' ][ settingName ].replaceAll("\n", '<br/>') : allUserSettings[ 'header' ][ settingName ]);
                                } else {
                                  text = text.replaceAll('{header.' + settingName + '}', typeof defaultElement.values[settingName] === 'string' ? defaultElement.values[settingName].replaceAll("\n", '<br/>') : defaultElement.values[settingName]);
                                }
                              })
                            }

                            // Load header settings into the header element.
                            header = text.replace('<title></title>', '<title>' + currentTemplateName + '</title>');
                          })

                      fetch('/themes/' + currentTheme + '/footer.html')
                          .then((r) => r.text())
                          .then(text => {
                            loadState.footer = true;

                            // Put all footer variables into footer.
                            let allUserSettings = {};
                            Object.values(themeJson.templates.default).forEach(el => {
                              if ( el.type === 'footer' ) {
                                allUserSettings[ 'footer' ] = el.values;
                              }
                            })

                            // Override default values with user defined values.
                            let allUserSettingsTmp = userData;
                            if ( typeof allUserSettingsTmp[ 'footer' ] !== 'undefined' ) {
                              Object.keys(allUserSettingsTmp[ 'footer' ]).forEach(key => {
                                allUserSettings[ 'footer' ][ key ] = allUserSettingsTmp[ 'footer' ][ key ];
                              })
                            }

                            if ( typeof allUserSettings[ 'footer' ] !== 'undefined' ) {
                              let defaultElement = themeJson.templates['default'].find(e => e.type === "footer");
                              // Replace boolean values.
                              Object.keys(defaultElement.values).forEach(settingName => {
                                // eslint-disable-next-line no-useless-escape
                                let matches = text.match(new RegExp("{/footer." + settingName + "}(\[\\S\\s\]*?){footer." + settingName + "/}", "m"));
                                if ( matches !== null ) {
                                    // eslint-disable-next-line no-useless-escape
                                  text = text.replaceAll(new RegExp("{/footer." + settingName + "}(\[\\S\\s\]*?){footer." + settingName + "/}", "gm"), (typeof allUserSettings[ 'footer' ][ settingName ]  !== 'undefined' ? allUserSettings[ 'footer' ][ settingName ] : defaultElement.values[settingName]) ? matches[ 1 ] : '');
                                }
                              })

                                // Replace regular values.
                              Object.keys(defaultElement.values).forEach(settingName => {
                                if (typeof allUserSettings['footer'][settingName] !== 'undefined'){
                                  text = text.replaceAll('{footer.' + settingName + '}', typeof allUserSettings[ 'footer' ][ settingName ] === 'string' ? allUserSettings[ 'footer' ][ settingName ].replaceAll("\n", '<br/>') : allUserSettings[ 'footer' ][ settingName ]);
                                } else {
                                  text = text.replaceAll('{footer.' + settingName + '}', typeof defaultElement.values[settingName] === 'string' ? defaultElement.values[settingName].replaceAll("\n", '<br/>') : defaultElement.values[settingName]);
                                }
                              })
                            }

                            // Load footer settings into the footer element.
                            footer = text;
                          })

                      // Prevents race condition on element load.
                      let htmlTmp = {};
                      currentElements.forEach((element, i) => {
                        [
                          '/themes/' + currentTheme + '/elements/' + currentTemplate + '/' + element.type + '/' + currentStyle + '.html',
                          '/themes/' + currentTheme + '/elements/core/' + element.type + '/' + currentStyle + '.html',
                        ].forEach(urlToTry => {
                          let responseCode = null;
                          fetch(urlToTry)
                              .then(r => {
                                responseCode = r.status;

                                return r.text();
                              })
                              .then(text => {
                                if ( loadState[ i + element.id ] === true ) {
                                  return;
                                }

                                if ( responseCode > 400 ) {
                                  return;
                                }

                                if ( text.match(/bundle\.js/) ) {
                                  return;
                                }

                                loadState[ i + element.id ] = true;

                                // Insert element ID into element.
                                text = text.replaceAll('{elementId}', 'el-' + element.id);

                                // Replace element variables with the user settings or defaults.
                                if ( typeof element.values !== 'undefined' ) {
                                  let defaultElement = themeJson.templates[currentTemplate].find(e => e.type === element.type);
                                  if (typeof defaultElement === "undefined" || !defaultElement){
                                    defaultElement = themeJson.templates["default"].find(e => e.type === element.type);
                                  }
                                    // Replace all boolean logic.
                                  Object.keys(defaultElement.values).forEach(name => {
                                    // eslint-disable-next-line no-useless-escape
                                    let matches = text.match(new RegExp("{\/" + element.type + "\." + name + "}(\[\\S\\s\]*?){" + element.type + "." + name + "\/}", "m"));
                                    if ( matches !== null ) {
                                    // eslint-disable-next-line no-useless-escape
                                      text = text.replaceAll(new RegExp("{\/" + element.type + "\." + name + "}(\[\\S\\s\]*?){" + element.type + "." + name + "\/}", "gm"), (typeof element.values[name]  !== 'undefined' ? element.values[ name ] : defaultElement.values[name]) ? matches[ 1 ] : '');
                                    }
                                  })

                                    // Replace regular values.
                                  Object.keys(defaultElement.values).forEach(name => {
                                    if (typeof element.values[ name ] !== 'undefined'){
                                      text = text.replaceAll('{' + element.type + '.' + name + '}', typeof element.values[ name ] === 'string' ? element.values[ name ].replaceAll("\n", '<br/>') : element.values[ name ]);
                                    } else {
                                      text = text.replaceAll('{' + element.type + '.' + name + '}', typeof defaultElement.values[name] === 'string' ? defaultElement.values[name].replaceAll("\n", '<br/>') : defaultElement.values[name]);
                                    }
                                  });
                                }

                                // Hide all unused element variables
                                // eslint-disable-next-line no-useless-escape
                                text = text.replace(new RegExp("{" + element.id + "\..*?}", "gm"), '');

                                // Extract all <style> content for merging into head's <style> later.
                                let style = text.match(/<style>(.*)<\/style>/ms);
                                if ( style ) {
                                  globalStyles[ i + element.id ] = style[ 1 ];

                                    // Remove style from the element.
                                  text = text.replace(/<style>(.*)<\/style>/ms, '');
                                }

                                htmlTmp[ element.id ] = text;
                                checkLoadState();
                              })
                              .catch(e => {
                                console.error(e);
                                loadState[ element.id ] = true;
                                checkLoadState();
                              })
                        })
                      });

                      function checkLoadState() {
                        let skip = false;
                        Object.values(loadState).forEach(value => {
                          if ( ! value ) {
                            skip = true;
                          }
                        })

                        if ( skip ) {
                          return;
                        }

                        // Join all element HTMLs after all elements are loaded.
                        if (html === '') {
                          currentElements.forEach(el => {
                            html += htmlTmp[el.id];
                          })
                        }

                        // Put all style content into head.
                        let headerTmp = '';
                        if ( header.match('</style>') ) {
                          headerTmp = header.replace('</style>', Object.values(globalStyles).join('') + '</style>');
                        } else {
                          headerTmp = header.replace('</head>', '<style>' + Object.values(globalStyles).join('') + '</style>');
                        }

                        // Put footer style into head.
                        let footerTmp = '';
                        footer.split('<style>').forEach((content, i) => {
                          footer = footer.replace("footerBorder ", "");
                          if ( footer.split('<style>').length - 1 !== i ) {
                            let contentTmp = content.split('</style>');
                            headerTmp      = headerTmp.replace('</style>', contentTmp[ 0 ] + '</style>');
                        // Remove previous style code from footer section.
                            footerTmp      = footer.replace(contentTmp[ 0 ], '');
                          }
                        })

                        // Remove onclick functions
                        footer = footer.replace(/onclick=".*"/, "");
                        header = header.replace(/onclick=".*"/, "");

                        // Replace full_name values.
                        html = html.replace('[@bill_full_name@]', '[@bill_first_name@] [@bill_last_name@]');

                        if ( window.exportRequestTimeout ) {
                          window.clearTimeout(window.exportRequestTimeout);
                        }

                        document.getElementById('export-html-content').innerHTML                   = 'Loading...';
                        document.getElementById('export-html-content').parentElement.style.display = 'block';
                        document.getElementById('html-popup-bg').style.display                     = 'block';

                        window.exportRequestTimeout = window.setTimeout(() => {
                        // Request HTML parsing from the API and show the parsed HTML to the client.
                          fetch("https://dev.api.outsmart.digital/pretty-emails/get-export", {
                            method: "POST",
                            body: headerTmp + html + footerTmp,
                          }).then(res => res.json()).then(res => {
                            window.isPendingExportRequest = false;
                            if ( res.status !== 'success' ) {
                              alert('Export data API ' + res.status + ': ' + res.message);
                              return;
                            }

                            document.getElementById('export-html-content').innerHTML                   = res.message;
                            document.getElementById('export-html-content').parentElement.style.display = 'block';
                            document.getElementById('html-popup-bg').style.display                     = 'block';
                            document.getElementById('copy-to-clipboard-button').style.display                     = 'initial';
                          }).catch(e => {
                            window.isPendingExportRequest = false;
                            alert('Error requesting export data: ' + e);

                            document.getElementById('export-html-content').innerHTML                   = 'Loading...';
                            document.getElementById('export-html-content').parentElement.style.display = 'none';
                            document.getElementById('html-popup-bg').style.display                     = 'none';
                            document.getElementById('copy-to-clipboard-button').style.display                     = 'none';
                          });
                        }, 500);
                      }
                    }}
                >
                  Get Export Code
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </Fade>
      }

      <div className="previewHeader">
        <div className={clsx("headerDropdown", "headerItem")}>
          <Box sx={{ minWidth: 120 }} width={200} className="templateDropdown">
            <FormControl fullWidth size="small">
              <Select
                id="demo-simple-select"
                value={currentTemplate}
                onChange={(e) => {
                  setCurrentTemplate(e.target.value);
                  setIsElementEditorOpen(false);
                  setIsThemeSettingsOpen(false);
                  setIsThemeStyleOpen(false);
                  setIsElementPickerOpen(false);
                  setTabValue(0);

                  let iframe = document.getElementById('previewIframe');
                  iframe.addEventListener("load", function () {
                    iframe.contentWindow.document.documentElement.scrollTo({
                      top: 0,
                    });
                  });
                }}
              >
                {availableTemplates.map(template => {
                  if ( typeof template === 'string' ) {
                    return <ListSubheader>{template}</ListSubheader>;
                  } else {
                    return <MenuItem value={template.id}>{template.value}</MenuItem>
                  }
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className={clsx("headerTabs", "headerItem")}>
          <Box sx={{ width: '100%', }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={isMobilePreview} TabIndicatorProps={{
                style: {
                  backgroundColor: "#f5f6f8",
                }
              }} onChange={onTabChange} aria-label="Header tabs">
                <Tab className="headerTab" label={<DesktopWindowsIcon/>} {...tabProps(0)} style={{ width: '50%' }}/>
                <Tab className="headerTab" label={<PhoneAndroidIcon/>} {...tabProps(1)} style={{ width: '50%' }}/>
              </Tabs>
            </Box>
          </Box>
        </div>
        <div className={clsx("paymentStatus", "headerItem")}>
          <Button className="headerButtons" variant="contained"><a href={document.referrer + "pretty-emails"}> {isTrial ? '' : 'Save and '}Return To App</a></Button>
          {! isTrial && <Button id="exportButton" className="headerButtons" variant="contained" style={{ marginLeft: '5px' }} onClick={() => handlePopupClick(true)}><FileUploadIcon/> Export</Button>}
        </div>
        <div id={'html-popup-bg'} style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          background: 'rgba(0,0,0,0.4)',
          display: 'none'
        }} onClick={() => {
          document.getElementById('export-html-content').innerHTML                   = 'Loading...';
          document.getElementById('export-html-content').parentElement.style.display = 'none';
          document.getElementById('html-popup-bg').style.display                     = 'none';
          document.getElementById('copy-to-clipboard-button').style.display                     = 'none';
        }}/>
        <div style={{
          position: "fixed",
          top: '50%',
          left: '50%',
          transform: "translate(-50%, -50%)",
          width: 800,
          maxHeight: 'calc(100vh - 20%)',
          display: 'none',
          padding: '10px 20px 30px',
          borderRadius: 10,
          zIndex: 2,
          background: "#fff",
          border: "1px solid #eee",
          color: "#6b6b6b"
        }}>
          <h2>Export HTML</h2>
          <p>Copy the following content and paste it into your email template</p>
          <xmp id={'export-html-content'} style={{
            textAlign: 'left',
            background: '#666',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: 10,
            maxHeight: 230,
            overflowY: 'scroll',
            overflowX: 'scroll',
            cursor: 'pointer'
          }}>Loading...
          </xmp>
          <Button id={'copy-to-clipboard-button'} variant={"contained"} onClick={async () => {
            await navigator.clipboard.writeText(document.getElementById('export-html-content').innerHTML);
            window.alert('Copied!');
          }} style={{ marginRight: 20, display: "none", }}>Copy to clipboard</Button>
          <Button variant={"contained"} onClick={() => {
            document.getElementById('export-html-content').innerHTML                   = 'Loading...';
            document.getElementById('export-html-content').parentElement.style.display = 'none';
            document.getElementById('html-popup-bg').style.display                     = 'none';
            document.getElementById('copy-to-clipboard-button').style.display                     = 'none';
          }}>Close</Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
