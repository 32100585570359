import LinkIcon from '@mui/icons-material/Link';

const SiteLinks = {
	type: "site-links",
	name: "Site Links",
	icon: <LinkIcon/>,
	properties: [
		{
			name: "Site Links",
			description: "Add common links to your website here, and they will be included in all emails.",
			content: [
				{
					id: "show-site-links",
					label: "Show Site Links",
					type: "checkbox",
				},
				{
					id: "site-links-position",
					label: "Site Links Position",
					type: "select",
					options: {
						"top": "Top",
						"bottom": "Bottom",
					}
				},
				{
					id: "site-links-local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "site-links-font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "site-links-font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				}
			]
		},
		{
			name: "Link 1",
			content: [
				{
					id: "link-1-text",
					label: "Text",
					type: "input",
				},
				{
					id: "link-1-url",
					label: "Link/URL",
					type: "input",
				}
			]
		},
		{
			name: "Link 2",
			content: [
				{
					id: "link-2-text",
					label: "Text",
					type: "input",
				},
				{
					id: "link-2-url",
					label: "Link/URL",
					type: "input",
				}
			]
		},
		{
			name: "Link 3",
			content: [
				{
					id: "link-3-text",
					label: "Text",
					type: "input",
				},
				{
					id: "link-3-url",
					label: "Link/URL",
					type: "input",
				}
			]
		},
		{
			name: "Link 4",
			content: [
				{
					id: "link-4-text",
					label: "Text",
					type: "input",
				},
				{
					id: "link-4-url",
					label: "Link/URL",
					type: "input",
				}
			]
		}
	],
	values: {
		"show-site-links": true,
		"site-links-position": "bottom",
		"text-align": "left",
		"site-links-local-font-settings-activated": false,
		"site-links-font-color": "#6b6b6b",
		"site-links-font-size": 15,
		"link-1-text": "Shop",
		"link-2-text": "About",
		"link-3-text": "Contact",
	},
};

export default SiteLinks;
