import TextSnippetIcon from "@mui/icons-material/TextSnippet";



const IntroductionText = {
	type: "introduction-text",
	name: "Introduction Text",
	icon: <TextSnippetIcon/>,
	properties: [
		{
			name: "Introduction text",
			content: [
				{
					id: "greeting",
					label: "Greeting",
					type: "input",
				},
				{
					id: "customer-name-format",
					label: "Customer name format",
					type: "select",
					options: {
						"": "None - Do not show greeting",
						"bill_first_name": "First Name",
						"bill_full_name": "Full Name",
						"bill_last_name": "Last Name",
					}
				},
				{
					id: "text",
					label: "Text",
					type: "textBox",
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		},
	],
	values: {
		"greeting": "Hey Mr./Mrs.",
		"customer-name-format": "bill_last_name",
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default IntroductionText;
