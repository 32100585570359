import HomeIcon from '@mui/icons-material/Home';

const ReturnToHomepage = {
  type: "return-to-homepage",
  name: "Return to Homepage",
  icon: <HomeIcon/>,
  properties: [
    {
      name: "Return to Homepage",
      content: [
        {
          id: "return-to-homepage-text",
          label: "Return to Homepage Text",
          type: "textBox",
        },
        {
          id: "link-text",
          label: "Link Text",
          type: "input"
        },
        {
          id: "link-to",
          label: "Link to Homepage",
          type: "input"
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    }
  ],
  values: {
    "return-to-homepage-text": "You may return to the homepage here:",
    "link-text": "[@CONFIG:COMPANY_NAME@]",
    "link-to": "",
    "text-align": "left",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10
  },
};

export default ReturnToHomepage;
