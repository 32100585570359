import Grid4x4Icon from '@mui/icons-material/Grid4x4';


const Spacer = {
    type: "spacer",
    name: "Spacer",
    icon: <Grid4x4Icon/>,
    properties: [
        {
            name: "Spacer",
            content: [
                {
                    id: "spacer-size",
                    label: "Spacer Size",
                    type: "toggle",
                    options: {
                        "min": 5,
                        "max": 100,
                    }
                },
            ]
        }
    ],
    values: {
        "spacer-size": 20,
    },
};

export default Spacer;
