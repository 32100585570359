import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PresetPopup from "./PresetPopup";
import themeImage from "../../../../src/assets/images/theme-1.png";

function ThemeStyleSettings() {

  const [ presetPopup, setPresetPopup ] = useState(false);

  const handlePresetPopup = (e) => {
    setPresetPopup(e.target.name);
  }

  return(
    <>
      <div id="themeStyleSettings">
        <div className="body">
          <div className="theme">
            <p
              className="themeTitle"
            >
              DAWN / Style-1
            </p>
            <div className="themeImage">
              <img
                alt="change this" src={themeImage}/>
            </div>
            <Button
              className="button"
              variant="contained"
              name="Style-1"
              onClick={handlePresetPopup}
            >
              Apply this Preset
            </Button>
          </div>
          <div className="theme">
            <p
              className="themeTitle"
            >
              DAWN / Style-2
            </p>
            <div className="themeImage">
              <img
                alt="change this" src={themeImage}/>
            </div>
            <Button
              className="button"
              variant="contained"
              name="Style-2"
              onClick={handlePresetPopup}
            >
              Apply this Preset
            </Button>
          </div>
          <div className="theme">
            <p
              className="themeTitle"
            >
              DAWN / Style-3
            </p>
            <div className="themeImage">
              <img
                alt="change this" src={themeImage}/>
            </div>
            <Button
              className="button"
              variant="contained"
              name="Style-3"
              onClick={handlePresetPopup}
            >
              Apply this Preset
            </Button>
          </div>
        </div>
      </div>
      {presetPopup &&
      <PresetPopup
        name={presetPopup}
        handlePresetPopup={setPresetPopup}
      />
      }
    </>
  );
}

export default ThemeStyleSettings;