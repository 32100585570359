import React from 'react';
import './App.css';
import Editor from "./containers/Editor/Editor";
import { getInitData } from "./utilities";
import outsmartLogo from "./assets/images/os-logo.svg";

function App() {
  const initData = getInitData();
  if ( initData === null ) {
    return <div style={{
      textAlign: 'center',
      margin: '100px auto',
      width: 600,
    }}>
      <img style={{
        display: 'block',
        width: 200,
        margin: '0 auto 40px',
      }} src={outsmartLogo} alt={"Outsmart logo"}/>
      <h3>Something went wrong, could not initialize the editor</h3>
      Please log in to your Outsmart dashboard, select the Pretty Emails item in the sidebar, and choose the theme to
      open the editor.</div>;
  }

  // todo - verify the session and subscription here.

  return (
    <Editor initData={initData}/>
  );
}

export default App;
