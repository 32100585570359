import Heading from "../containers/Editor/elements/Heading";
import TextElement from "../containers/Editor/elements/TextElement";
import TextColumns from "../containers/Editor/elements/TextColumns";
import ImageElement from "../containers/Editor/elements/ImageElement";
import ButtonElement from "../containers/Editor/elements/ButtonElement";
import Divider from "../containers/Editor/elements/Divider";
import Spacer from "../containers/Editor/elements/Spacer";

const commonElements      = [
  Heading,
  TextElement,
  TextColumns,
  ImageElement,
  ButtonElement,
  Divider,
  Spacer
];

export default commonElements;
