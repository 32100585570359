import TextSnippetIcon from "@mui/icons-material/TextSnippet";

const ClosingText = {
	type: "closing-text",
	name: "Closing Text",
	icon: <TextSnippetIcon/>,
	properties: [
		{
			name: "Closing Text",
			description: "This text is used in all emails as a signature, to provide contact details and offer help if needed.",
			content: [
				{
					id: "closing-text",
					label: "Text",
					type: "textBox",
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			],
		},
	],
	values: {
		"closing-text": "Please do not hesitate to give us a call on or send an email to hello@perceptiv.digital if you have any questions at all.\n" +
			"\n" +
			"Many thanks,\n" +
			"The Flex Equipment team",
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default ClosingText;
