import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ElementOptions from "./ElementOptions";
import ElementPicker from "./ElementPicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ThemeSettings from "./ThemeSettings";
import SendTestPopup from "./SendTestPopup";
import ScreenshotsPopup from "./ScreenshotsPopup";
import Slide from "@mui/material/Slide";
import { createUniqueElementId, getElementByType } from "../../../utilities";
import MakingScreenshotsPopup from "./MakingScreenshotsPopup";
import availableTemplates from "../../../themeData/availableTemplates";

function Sidebar({
  currentElements,
  setCurrentElements,
  templateElements,
  commonElements,
  currentTemplate,
  setIsThemeStyleOpen,
  isThemeSettingsOpen,
  setIsThemeSettingsOpen,
  value,
  setValue,
  themeSettings,
  setThemeSettings,
  isElementEditorOpen,
  setIsElementEditorOpen,
  elementEditorElement,
  setElementEditorElement,
  isElementPickerOpen,
  setIsElementPickerOpen,
  setCurrentSettings,
  setCurrentTemplate,
  themeJson,
  reloadHeaderAndFooter,
  userData,
  setUserData,
}) {
  const [ isSendTestPopupOpen, setIsSendTestPopupOpen ]       = useState(false);
  const [ isScreenshotsPopupOpen, setIsScreenshotsPopupOpen ] = useState(false);
  const [ isMakingScreenshots, setIsMakingScreenshots ] = useState(false);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box sx={{ div: 2 }} style={{ paddingRight: 0, }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleSendTestPopup = () => {
    setIsSendTestPopupOpen(! isSendTestPopupOpen);
  }

  const handleScreenshotsPopup = () => {
    setIsScreenshotsPopupOpen(! isScreenshotsPopupOpen);
  }


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const openAddElement = () => {
    setIsElementPickerOpen(! isElementPickerOpen)
  }

  const pushElement = (type) => {
    let elementObject = getElementByType(type);
    elementObject.id = createUniqueElementId();

    let tmp = [ ...currentElements ];
    tmp.push(elementObject);
    setCurrentElements(tmp);
  }

  function handleOnDragEnd(result) {
    if ( ! result.destination ) return;
    const items           = Array.from(currentElements);
    const [ reorderItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);

    setCurrentElements(items);
  }

  let allTemplates = availableTemplates.filter(function (value, index, arr){
    return typeof value !== 'string';
  });

  function changeCurrentTemplate(template) {

    return true;
  }

  return (
    <div id="sidebar">
      <div className="sidebarOptions">
        {isThemeSettingsOpen &&
        <div className="themeSettingsElement">
          <div className={"settingsComponent"}>
            {themeSettings.component}
          </div>
        </div>
        }
        {isElementPickerOpen &&
        <ElementPicker
          templateElements={templateElements}
          commonElements={commonElements}
          currentTemplate={currentTemplate}
          pushElement={pushElement}
          setIsElementPickerOpen={setIsElementPickerOpen}
          isElementPickerOpen={isElementPickerOpen}
        />
        }

        {isElementEditorOpen &&
        <ElementOptions
          setIsElementEditorOpen={setIsElementEditorOpen}
          currentElement={elementEditorElement}
          currentElements={currentElements}
          setCurrentElements={setCurrentElements}
          isElementEditorOpen={isElementEditorOpen}
          setCurrentSettings={setCurrentSettings}
          themeJson={themeJson}
          currentTemplate={currentTemplate}
        />}

        {! isElementPickerOpen && ! isElementEditorOpen && ! isThemeSettingsOpen &&
        <Slide
          in={! isElementPickerOpen && ! isElementEditorOpen && ! isThemeSettingsOpen}
          direction="left"
          style={{
            transform: "translate(100%)",
            transition: "transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Content" {...a11yProps(0)} style={{ width: '50%' }}/>
                <Tab label="Theme settings" {...a11yProps(1)} style={{ width: '50%' }}/>
              </Tabs>
            </Box>
            <div>
              <TabPanel value={value} index={0}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="elements" style={{ padding: '5px', }}>
                    {(provided) => (
                      <ul
                        className="sidebarElements"
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {currentElements.map((element, index) => {
                            return (
                              <Draggable key={element.id + index} draggableId={element.id + index} index={index}>
                                {(provided) => (
                                  <li
                                    key={element.id + index}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    onClick={() => {
                                      setElementEditorElement(element);
                                      setIsElementEditorOpen(true);
                                    }}>
                                    <div className="elementIcon">
                                      {element.icon}
                                    </div>
                                    <p>
                                      {element.name}
                                    </p>
                                  </li>
                                )}
                              </Draggable>
                            );
                          }
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="addContentElement" onClick={openAddElement}>
                  <div className="elementIcon">
                    <AddIcon/>
                  </div>
                  <p>
                    Add Content
                  </p>
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <ThemeSettings
                  setThemeSettings={setThemeSettings}
                  setIsThemeSettingsOpen={setIsThemeSettingsOpen}
                  setIsThemeStyleOpen={setIsThemeStyleOpen}
                  reloadHeaderAndFooter={reloadHeaderAndFooter}
                  themeJson={themeJson}
                  userData={userData}
                  setUserData={setUserData}
                  setIsElementEditorOpen={setIsElementEditorOpen}
                />
              </TabPanel>
            </div>
          </Box>
        </Slide>
        }
        {isSendTestPopupOpen &&
        <SendTestPopup
          popupClose={handleSendTestPopup}
          currentTemplate={currentTemplate}
          isSendTestPopupOpen={isSendTestPopupOpen}
        />
        }
        {isScreenshotsPopupOpen &&
        <ScreenshotsPopup
          popupClose={handleScreenshotsPopup}
          isScreenshotsPopupOpen={isScreenshotsPopupOpen}
          changeCurrentTemplate={changeCurrentTemplate}
          allTemplates={allTemplates}
          setCurrentTemplate={setCurrentTemplate}
          setIsMakingScreenshots={setIsMakingScreenshots}
        />
        }
        {isMakingScreenshots &&
          <MakingScreenshotsPopup
            setIsMakingScreenshots={setIsMakingScreenshots}
            setCurrentTemplate={setCurrentTemplate}
            allTemplates={allTemplates}
          />
        }

      </div>
    </div>
  );
}

export default Sidebar;
