import React from "react";
import { Button, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";

function RemoveElementPopup({
  popupClose,
  currentElement,
  currentElements,
  setCurrentElements,
  setIsElementEditorOpen,
  isRemoveElementPopupOpen,
}) {
  const removeElement = (element) => {
    let tmp = [ ...currentElements ];
    tmp.forEach((elementTmp, i) => {
      if ( elementTmp.id === element.id ) {
        delete tmp[ i ];
      }
    })

    // .filter is used to remove the undefined array element we just deleted above.
    setCurrentElements(tmp.filter(e=>e));
    popupClose();
    setIsElementEditorOpen(false);
  }

  return (
    <Fade
      in={isRemoveElementPopupOpen}
      timeout={600}
      style={{ transitionDelay: '100ms' }}
    >
      <div className="popupWrapper" onClick={e => {
        if ( e.target.classList.contains('popupWrapper') ) {
          popupClose();
        }
        e.stopPropagation();
        return false;
      }}>
        <Paper className="popup">
          <div className="popupElementsWrapper">
            <div className="popupHeading">
              <h2 style={{ marginTop: 0 }}>Remove element</h2>
              <CloseIcon
                onClick={popupClose}
                className="closeButton"
              />
            </div>
            <div className="popupBody">
              <div className="description">
                <p>Are you sure? This will remove the section from the email.</p>
              </div>
            </div>
            <div className="popupFooter">
              <Button
                  className="cancelButton"
                  onClick={popupClose}
                  variant="outlined"
                  size="small"
              >
                Cancel
              </Button>
              <Button
                  className="removeButton"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    removeElement(currentElement)
                  }}>
                Remove element
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </Fade>
  );
}

export default RemoveElementPopup;
