import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";

const Divider = {
	type: "divider",
	name: "Divider",
	icon: <SafetyDividerIcon/>,
	properties: [
		{
			name: "Divider",
			content: [
				{
					id: "divider-size",
					label: "Divider Size",
					type: "toggle",
					options: {
						min: 1,
						max: 10,
					}
				},
				{
					id: "divider-color",
					label: "Divider Color",
					type: "colorPicker"
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		}
	],
	values: {
		"divider-size": 1,
		"divider-color": "#cccccc",
		"spacing-size": 10
	},
};

export default Divider;
