import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Slide from "@mui/material/Slide";

function ElementPickerHeader({
  setIsElementPickerOpen,
  isElementPickerOpen,
}) {

  return (
    <Slide direction="right" in={isElementPickerOpen}>
      <div className="headerBackButton">
        <div
          className="heading"
          onClick={
            () => {
              setIsElementPickerOpen(false);
            }
          }
        >
          <ArrowBackIosIcon
            className="arrowBackIcon"
          />
          <b>Add Content</b>
        </div>
      </div>
    </Slide>
  );
}

export default ElementPickerHeader;