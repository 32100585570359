import TextSnippetIcon from "@mui/icons-material/TextSnippet";

const TextColumns = {
  type: "text-columns",
  name: "Text columns",
  icon: <TextSnippetIcon/>,
  properties: [
    {
      name: "Text Columns",
      content: [
        {
          id: "title1",
          label: "Title column 1",
          type: "input",
        },
        {
          id: "text1",
          label: "Text column 1",
          type: "textBox",
        },
        {
          id: "title2",
          label: "Title column 2",
          type: "input"
        },
        {
          id: "text2",
          label: "Text column 2",
          type: "textBox",
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
  ],
  values: {
    "title1": "Title",
    "text1": "This is a text section, that fills the 1st column.",
    "title2": "Title",
    "text2": "This is a text section, that fills the 2nd column.",
    "text-align": "left",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10
  },
};

export default TextColumns;
