import CreditCardIcon from '@mui/icons-material/CreditCard';

const OnlinePaymentReminder = {
	type: "online-payment-reminder",
	name: "Online Payment Reminder",
	icon: <CreditCardIcon/>,
	properties: [
		{
			name: "Online Payment Reminder",
			content: [
				{
					id: "online-payment-description",
					label: "Online Payment Description",
					type: "textBox",
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		},
	],
	values: {
		"online-payment-description": "You can now pay your statement online with a credit card by going to",
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default OnlinePaymentReminder;
