import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';

const StoreInfo = {
	type: "store-info",
	name: "Store Info/Footer",
	icon: <StoreMallDirectoryIcon/>,
	properties: [
		{
			name: "Store Info",
			content: [
				{
					id: "website",
					label: "Website",
					type: "input",
				},
				{
					id: "store-info-logo",
					label: "Logo",
					type: "input",
				},
				{
					id: "store-details",
					label: "Store Details",
					type: "textBox",
				},
			]
		}
	],
	values: {},
};

export default StoreInfo;
