import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const PaymentMethods = {
	type: "payment-methods",
	name: "Payment Methods",
	icon: <CurrencyExchangeIcon/>,
	properties: [
		{
			name: "Payment Methods",
			content: [
				{
					id: "show-cc",
					label: "Show Credit Card",
					type: "checkbox",
				},
				{
					id: "show-bpay",
					label: "Show Telephone & Internet Banking - BPAY",
					type: "checkbox",
				},
				{
					id: "show-direct",
					label: "Show Direct Bank Deposit or Internet Transfer",
					type: "checkbox",
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		},
	],
	values: {
		"show-cc": true,
		"show-bpay": true,
		"show-direct": true,
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default PaymentMethods;
