import React, {useState} from "react";
import ColorPicker from "../ColorPicker";
import Slider from "@mui/material/Slider";
import {getUserSettings, saveGlobalElement} from "../../../../utilities";
import {Collapse, Switch} from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

function GlobalStyles({
                          reloadHeaderAndFooter,
                          themeJson,
                          userData,
                          setUserData
                      }) {

    const [values, setValues] = useState(null);

    // todo for sometime in future - put this into useEffect.
    if ( values === null ) {
        let valuesTmp = {};
        Object.values(themeJson.templates.default).forEach(el => {
            if ( el.type === 'header' ) {
                valuesTmp = el.values;
            }
        })

        let userSettings = userData;
        if ( typeof userSettings[ 'header' ] !== 'undefined' ) {
            Object.keys(userSettings[ 'header' ]).forEach(settingName => {
                valuesTmp[ settingName ] = userSettings[ 'header' ][ settingName ];
            })

            setValues(valuesTmp);
            return;
        } else {
            setValues(valuesTmp);
            return;
        }
    }

    const handleChange = (event) => {
        // noinspection JSUnresolvedFunction
        let valuesTmp                = window.structuredClone(values);
        if (typeof event.id !== "undefined"){
            if (typeof event.family !== "undefined"){
                valuesTmp[ 'global-font-type' ] = event.family;
            } else {
                valuesTmp[ event.id ] = event.type === 'checkbox' ? event.checked : event.value;
            }
        } else {
            if ( typeof event.target.id === 'undefined' ) {
                valuesTmp[ event.target.name ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            } else {
                valuesTmp[ event.target.id ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            }
        }
        setValues(valuesTmp);

        saveGlobalElement('header', valuesTmp, userData).then(()=>{
            getUserSettings().then(res => setUserData(res));
        });
        reloadHeaderAndFooter();
    }

    return (
        <div className="settingsContainer">
            <div className="settingsSection">
                <p className="settingsHeading"><b>Font</b></p>
                <FormControl sx={{width: "90%", marginBottom: "10px"}}>
                    <InputLabel id="demo-simple-select-label">Font Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="global-font-type"
                        name="global-font-type"
                        value={typeof values[ 'global-font-type' ] === 'undefined' ? '' : values[ 'global-font-type' ]}
                        label="Font type"
                        onChange={handleChange}
                    >
                        <MenuItem value="Roboto" style={{fontFamily: 'Roboto'}}>Roboto</MenuItem>
                        <MenuItem value="Arial" style={{fontFamily: 'Arial'}}>Arial</MenuItem>
                        <MenuItem value="Times New Roman" style={{fontFamily: 'Times New Roman'}}>Times New Roman</MenuItem>
                        <MenuItem value="Georgia" style={{fontFamily: 'Georgia'}}>Georgia</MenuItem>
                        <MenuItem value="Google Sans" style={{fontFamily: 'Google Sans'}}>Google Sans</MenuItem>
                        <MenuItem value="cursive" style={{fontFamily: 'cursive'}}>Cursive</MenuItem>
                        <MenuItem value="fangsong" style={{fontFamily: 'fangsong'}}>Fangsong</MenuItem>
                        <MenuItem value="fantasy" style={{fontFamily: 'fantasy'}}>Fantasy</MenuItem>
                        <MenuItem value="inherit" style={{fontFamily: 'inherit'}}>Inherit</MenuItem>
                        <MenuItem value="initial" style={{fontFamily: 'initial'}}>Initial</MenuItem>
                        <MenuItem value="math" style={{fontFamily: 'math'}}>Math</MenuItem>
                        <MenuItem value="monospace" style={{fontFamily: 'monospace'}}>Monospace</MenuItem>
                        <MenuItem value="sans-serif" style={{fontFamily: 'sans-serif'}}>Sans Serif</MenuItem>
                        <MenuItem value="serif" style={{fontFamily: 'serif'}}>Serif</MenuItem>
                    </Select>
                </FormControl>
                <ColorPicker
                    hex={typeof values[ 'global-font-color' ] === 'undefined' ? '' : values[ 'global-font-color' ]}
                    label="Font Color"
                    id="global-font-color"
                    setColor={handleChange}
                    style={{
                        marginBottom: "10px"
                    }}
                />
                <p style={{
                    marginBottom: "10px",
                    display: "flex",
                    fontWeight: "600",
                }}>Font size</p>
                <Slider
                    style={{
                        width: "90%",
                    }}
                    name="global-font-size"
                    className="globalFontSize"
                    onChange={handleChange}
                    min={14}
                    max={22}
                    defaultValue={typeof values[ 'global-font-size' ] === 'undefined' ? '' : values[ 'global-font-size' ]}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                />
            </div>
            <div className="settingsSection">
                <p className="settingsHeading"><b>Buttons</b></p>
                <div className="settingsDescription">
                    Buttons are only shown on some emails, such as the Order Confirmation and Back in Stock Notification emails.
                </div>
                <div className="applyGlobalSettings">
                    <FormControlLabel
                        control={
                            <Switch
                                id='global-button-styles-is-applied'
                                defaultChecked={typeof values[ 'global-button-styles-is-applied' ] === 'undefined' ? '' : values[ 'global-button-styles-is-applied' ]}
                                onChange={handleChange}
                            />
                        }
                        label='Apply global button style'
                    />
                </div>
                <Collapse style={{width: "90%"}} in={typeof values[ 'global-button-styles-is-applied' ] === 'undefined' ? false : values[ 'global-button-styles-is-applied' ]}>
                    <ColorPicker
                        hex={typeof values[ 'global-button-background-color' ] === 'undefined' ? '' : values[ 'global-button-background-color' ]}
                        label="Button background color"
                        id="global-button-background-color"
                        setColor={handleChange}
                    />
                    <ColorPicker
                        hex={typeof values[ 'global-button-text-color' ] === 'undefined' ? '' : values[ 'global-button-text-color' ]}
                        label="Button text color"
                        id="global-button-text-color"
                        setColor={handleChange}
                    />
                </Collapse>

            </div>
            <div className="settingsSection">
                <p className="settingsHeading"><b>Accents</b></p>
                <div className="settingsDescription">
                    Accents are the backgrounds of elements such as "Items Ordered" in the order receipt email or "Items that have been delivered" in the Proof of Delivery email.
                </div>
                <div className="applyGlobalSettings">
                    <FormControlLabel
                        control={
                            <Switch
                                id='global-accent-styles-is-applied'
                                defaultChecked={typeof values[ 'global-accent-styles-is-applied' ] === 'undefined' ? '' : values[ 'global-accent-styles-is-applied' ]}
                                onChange={handleChange}
                            />
                        }
                        label='Apply global accent style'
                    />
                </div>
                <Collapse style={{width: "90%"}} in={typeof values[ 'global-accent-styles-is-applied' ] === 'undefined' ? false : values[ 'global-accent-styles-is-applied' ]}>
                    <ColorPicker
                        hex={typeof values[ 'global-accent-color-1' ] === 'undefined' ? '' : values[ 'global-accent-color-1' ]}
                        label="Accent color 1"
                        id="global-accent-color-1"
                        setColor={handleChange}
                    />
                    <ColorPicker
                        hex={typeof values[ 'global-accent-color-2' ] === 'undefined' ? '' : values[ 'global-accent-color-2' ]}
                        label="Accent color 2"
                        id="global-accent-color-2"
                        setColor={handleChange}
                    />
                </Collapse>

            </div>
            <div className="settingsSection">
                <p className="settingsHeading"><b>Spacing</b></p>
                <div className="settingsDescription">

                </div>
                <div className="applyGlobalSettings">
                    <FormControlLabel
                        control={
                            <Switch
                                id='global-spacing-styles-is-applied'
                                defaultChecked={typeof values[ 'global-spacing-styles-is-applied' ] === 'undefined' ? '' : values[ 'global-spacing-styles-is-applied' ]}
                                onChange={handleChange}
                            />
                        }
                        label='Apply global spacing style'
                    />
                </div>
                <Collapse style={{width: "90%"}} in={typeof values[ 'global-spacing-styles-is-applied' ] === 'undefined' ? false : values[ 'global-spacing-styles-is-applied' ]}>
                    <p style={{
                        marginBottom: "10px",
                        display: "flex",
                        fontWeight: "600",
                    }}>Elements padding</p>
                    <Slider
                        style={{
                            marginTop: "10px",
                            width: "90%",
                        }}
                        name="global-elements-padding"
                        className="globalElementsPadding"
                        onChange={handleChange}
                        min={0}
                        max={50}
                        defaultValue={typeof values[ 'global-elements-padding' ] === 'undefined' ? '' : values[ 'global-elements-padding' ]}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                    />
                </Collapse>
            </div>
        </div>
    );
}

export default GlobalStyles;