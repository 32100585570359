import React, {useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import clsx from "clsx";
import TextField from "@mui/material/TextField";
import { getUserSettings, saveGlobalElement } from "../../../../utilities";
import fbNoBorder from "../../../../assets/images/fb-no-border.png";
import fbWhiteNoBorder from "../../../../assets/images/fb-white-no-border.png";
import fbBlackOutline from "../../../../assets/images/fb-black-outline.png";
import fbClassic from "../../../../assets/images/fb-classic.png";
import fbBlackInline from "../../../../assets/images/fb-black-inline.png";
import fbWhiteOutline from "../../../../assets/images/fb-white-outline.png";
import fbClassicOutline from "../../../../assets/images/fb-classic-outline.png";
import fbClassicInline from "../../../../assets/images/fb-classic-inline.png";
import fbBlackRounded from "../../../../assets/images/fb-black-rounded.png";
import fbWhiteRoundedOutline from "../../../../assets/images/fb-white-rounded-outline.png";
import fbClassicRoundedOutline from "../../../../assets/images/fb-classic-rounded-outline.png";
import fbClassicRounded from "../../../../assets/images/fb-classic-rounded.png";
import fbBlackRoundedOutline from "../../../../assets/images/fb-black-rounded-outline.png";
import {Switch} from "@material-ui/core";
import ColorPicker from "../ColorPicker";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

function SocialSettings({ reloadHeaderAndFooter, themeJson, userData, setUserData }) {

  const [ values, setValues ]                         = useState(null);
  const [ isLocalFontSettingsActive, setIsLocalFontSettingsActive ] = useState(false);
  let themeJsonDefaultValue = typeof themeJson.templates["footer"] !== "undefined" ? themeJson.templates["footer"].values : themeJson.templates['default'].find(e => e.type === "footer").values;

  useEffect(() => {
    setIsLocalFontSettingsActive(typeof values[ 'social-local-font-settings-activated' ] === 'undefined' ? false : values[ 'social-local-font-settings-activated' ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollBack = (elementId) => {
    let iframe = document.getElementById('previewIframe');
    let element = iframe.contentWindow.document.getElementById(elementId);
    let elementOffset  = element.offsetTop;

    iframe.addEventListener("load", function () {
      iframe.contentWindow.document.documentElement.scrollTo({
        top: elementOffset,
      });
    });
  }

  // todo for sometime in future - put this into useEffect.
  if ( values === null ) {
    let valuesTmp = {};
    Object.values(themeJson.templates.default).forEach(el => {
      if ( el.type === 'footer' ) {
        valuesTmp = el.values;
      }
    })

    let userSettings = userData;
    if ( typeof userSettings[ 'footer' ] !== 'undefined' ) {
      Object.keys(userSettings[ 'footer' ]).forEach(settingName => {
        valuesTmp[ settingName ] = userSettings[ 'footer' ][ settingName ];
      })

      setValues(valuesTmp);
      return;
    } else {
      setValues(valuesTmp);
      return;
    }
  }

  const handleSocialIconChange = (event) => {
    // noinspection JSUnresolvedFunction
    let valuesTmp = window.structuredClone(values);
    valuesTmp['social-icon-style'] = event.target.id;
    setValues(valuesTmp);

    saveGlobalElement('footer', valuesTmp, userData).then(()=>{
      getUserSettings().then(res => setUserData(res));
    });
    reloadHeaderAndFooter();
  }

  const handleLocalFontSettingsChange = (event) => {
    setIsLocalFontSettingsActive(event.target.checked);
  }

  const handleChange = (event) => {
    // noinspection JSUnresolvedFunction
    let valuesTmp                = window.structuredClone(values);

    if ( event.value ){
      valuesTmp[ event.id ] = event.value;
    } else {
      if (event.target.id){
        valuesTmp[ event.target.id ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      } else {
        valuesTmp[ event.target.name ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      }
    }

    setValues(valuesTmp);
    saveGlobalElement('footer', valuesTmp, userData).then(()=>{
      getUserSettings().then(res => setUserData(res));
    });
    reloadHeaderAndFooter();
    scrollBack("social");
  }

  return (
    <div className="settingsContainer">
      <div className="settingsSection">
        <p className="settingsHeading">
          <b>General Settings</b>
        </p>
        <ColorPicker
            className="colorPicker"
            hex={typeof values[ 'store-info-background-color' ] === 'undefined' ? themeJsonDefaultValue['store-info-background-color'] : values[ 'store-info-background-color' ]}
            label="Footer Background Color"
            id="store-info-background-color"
            setColor={handleChange}
        />
        <div className="settingsDescription">
          <p>
            This controls the padding above and below the selected element. If global padding is applied this will be overridden.
          </p>
        </div>
        <Box sx={{ width: "90%", }}>
          <Typography id="spacer-size" gutterBottom>
            Padding Spacing
          </Typography>
          <Slider
              name="social-link-spacing"
              onChange={handleChange}
              min={0}
              max={50}
              value={typeof values[ 'social-link-spacing' ] === 'undefined' ? themeJsonDefaultValue['social-link-spacing'] : values[ 'social-link-spacing' ]}
              aria-labelledby="social-link-spacing"
              valueLabelDisplay="auto"
          />
        </Box>
      </div>
      <div className="settingsSection">
        <p className="settingsHeading">
          <b>Icon styling</b>
        </p>
        <div className="settingsDescription">
          <p>
            Select styling and add links for the social services you use below.
          </p>
        </div>
        <FormControlLabel
          className={"elementOptionInput"}
          control={
            <Switch
              id='social-link-show-profiles'
              checked={typeof values[ 'social-link-show-profiles' ] === 'undefined' ? themeJsonDefaultValue['social-link-show-profiles'] : values[ 'social-link-show-profiles' ]}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          }
          label="Show social profiles"
        />
        { values[ 'social-link-show-profiles' ] === true &&
        <div>
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <p
              style={{
                textAlign: "start",
              }}
            >
              Social icon style
            </p>
            <div className="thumbnails">
              <div className="thumbnailsRow">
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "no-border" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                    // handleChange(e);
                  }}
                >
                  <img alt="fb-no-border" className="imagePickerImage" id="no-border"
                    src={fbNoBorder}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "white-no-border" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                  style={{
                    backgroundColor: "#ddd"
                  }}
                >
                  <img alt="fb-white-no-border" className="imagePickerImage" id="white-no-border"
                    src={fbWhiteNoBorder}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "black-outline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                >
                  <img alt="fb-black-outline" className="imagePickerImage" id="black-outline"
                    src={fbBlackOutline}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "classic" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                >
                  <img alt="fb-classic" className="imagePickerImage" id="classic"
                    src={fbClassic}/>
                </div>
              </div>
              <div className="thumbnailsRow">
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "black-inline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                >
                  <img alt="fb-black-inline" className="imagePickerImage" id="black-inline"
                    src={fbBlackInline}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "white-outline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                  style={{
                    backgroundColor: "#ddd"
                  }}
                >
                  <img alt="fb-white-outline" className="imagePickerImage" id="white-outline"
                    src={fbWhiteOutline}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "classic-outline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                >
                  <img alt="fb-classic-outline" className="imagePickerImage" id="classic-outline"
                    src={fbClassicOutline}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "classic-inline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                >
                  <img alt="fb-classic-inline" className="imagePickerImage" id="classic-inline"
                    src={fbClassicInline}/>
                </div>
              </div>
              <div className="thumbnailsRow">
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "black-rounded" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                >
                  <img alt="fb-black-rounded" className="imagePickerImage" id="black-rounded"
                    src={fbBlackRounded}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "white-rounded-outline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                  style={{
                    backgroundColor: "#ddd"
                  }}
                >
                  <img alt="fb-white-rounded-outline" className="imagePickerImage" id="white-rounded-outline"
                    src={fbWhiteRoundedOutline}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "classic-rounded-outline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                  }}
                >
                  <img alt="fb-classic-rounded-outline" className="imagePickerImage" id="classic-rounded-outline"
                    src={fbClassicRoundedOutline}/>
                </div>
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "classic-rounded" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                    // handleChange(e);
                  }}
                >
                  <img alt="fb-classic-rounded" className="imagePickerImage" id="classic-rounded"
                    src={fbClassicRounded}/>
                </div>
              </div>
              <div className="thumbnailsRow">
                <div
                  className={clsx("thumbnail", values['social-icon-style'] === "black-rounded-outline" ? "selected" : "")}
                  onClick={(e) => {
                    handleSocialIconChange(e);
                    // handleChange(e);
                  }}
                >
                  <img alt="fb-black-rounded-outline" style={{ width: "62.5px" }} className="imagePickerImage"
                    id="black-rounded-outline"
                    src={fbBlackRoundedOutline}/>
                </div>
              </div>
            </div>
          </div>
          <TextField
            id="social-link-heading"
            className="textField"
            label="Heading (optional)"
            value={typeof values[ 'social-link-heading' ] === 'undefined' ? themeJsonDefaultValue['social-link-heading'] : values[ 'social-link-heading' ]}
            onChange={handleChange}
          />
          <FormControlLabel
              control={
                <Switch
                    id="social-local-font-settings-activated"
                    checked={typeof values[ 'social-local-font-settings-activated' ] === 'undefined' ? themeJsonDefaultValue['social-local-font-settings-activated'] : values[ 'social-local-font-settings-activated' ]}
                    onChange={(event) => {
                      handleLocalFontSettingsChange(event);
                      handleChange(event);
                    }}
                />
              }
              label="Apply local font settings"/>
          {isLocalFontSettingsActive &&
          <div>
            <ColorPicker
                hex={typeof values[ 'social-font-color' ] === 'undefined' ? themeJsonDefaultValue['social-font-color'] : values[ 'social-font-color' ]}
                label="Font Color"
                className="textField"
                id="social-font-color"
                setColor={handleChange}
            />
            <Box style={{margin: "20px 0"}}>
              <Typography id="social-font-size">
                Font Size
              </Typography>
              <Slider
                  name="social-font-size"
                  id="social-font-size"
                  onChange={handleChange}
                  min={5}
                  max={35}
                  defaultValue={typeof values[ 'social-font-size' ] === 'undefined' ? themeJsonDefaultValue['social-font-size'] : values[ 'social-font-size' ]}
                  aria-labelledby="social-font-size"
                  valueLabelDisplay="auto"
              />
            </Box>
          </div>
          }
        </div>
        }
      </div>
      { values[ 'social-link-show-profiles' ] === true &&
      <div className="settingsSection">
        <p className="settingsHeading">
          <b>Social media links</b>
        </p>
        <div className="settingsDescription">
          Add links to the social media services that you use.
        </div>
        <TextField
          id="social-link-facebook"
          className="textField"
          value={typeof values[ 'social-link-facebook' ] === 'undefined' ? themeJsonDefaultValue['social-link-facebook'] : values[ 'social-link-facebook' ]}
          label="Facebook"
          onChange={handleChange}
        />
        <TextField
          id="social-link-instagram"
          className="textField"
          value={typeof values[ 'social-link-instagram' ] === 'undefined' ? themeJsonDefaultValue['social-link-instagram'] : values[ 'social-link-instagram' ]}
          label="Instagram"
          onChange={handleChange}
        />
        <TextField
          id="social-link-twitter"
          className="textField"
          value={typeof values[ 'social-link-twitter' ] === 'undefined' ? themeJsonDefaultValue['social-link-twitter'] : values[ 'social-link-twitter' ]}
          label="Twitter"
          onChange={handleChange}
        />
        <TextField
          id="social-link-youtube"
          className="textField"
          value={typeof values[ 'social-link-youtube' ] === 'undefined' ? themeJsonDefaultValue['social-link-youtube'] : values[ 'social-link-youtube' ]}
          label="YouTube"
          onChange={handleChange}
        />
      </div>
      }
    </div>
  );
}
export default SocialSettings;
