import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function ThemeStyleHeader({
  setIsThemeSettingsOpen,
  themeSettings,
}) {

  return(
    <div className="headerBackButton">
      <div
        className="heading"
        onClick={
          () => {
            setIsThemeSettingsOpen(false);
          }
        }
      >
        <ArrowBackIosIcon className="arrowBackIcon"/>
        <b>{themeSettings.label}</b>
      </div>
    </div>
  );
}

export default ThemeStyleHeader;