import React, { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import Preview from "./components/Preview";
import Header from "./components/Header";
import ThemeStyleSettings from "./components/ThemeStyleSettings";
import elementsPerTemplate from "../../themeData/dawn";
import commonElements from "../../themeData/common";
import {
  createUniqueElementId,
  getElementByType,
  getExampleValue,
  getUserSettings,
  saveCurrentElements
} from "../../utilities";

function Editor({
  initData,
}) {
  const [ isMobilePreview, setIsMobilePreview ]           = useState(0);
  const [ isThemeStyleOpen, setIsThemeStyleOpen ]         = useState(false);
  const [ isThemeSettingsOpen, setIsThemeSettingsOpen ]   = useState(false);
  const [ themeJson, setThemeJson ]                       = useState(false);
  const [ themeSettings, setThemeSettings ]               = useState(false);
  const [ tabValue, setTabValue ]                         = useState(themeSettings ? 1 : 0);
  const [ isElementEditorOpen, setIsElementEditorOpen ]   = useState(false);
  const [ elementEditorElement, setElementEditorElement ] = useState(null);
  const [ isElementPickerOpen, setIsElementPickerOpen ]   = useState(false);

  const [ currentElements, setCurrentElements ] = useState([]);
  const [ currentTemplate, setCurrentTemplate ] = useState("order-confirmation");

  const [ userData, setUserData ] = useState(false);
  const [ isError, setIsError ]   = useState(false);

  // Ugly way to make the header and footer reloading work from sidebar as well as preview.
  const [ header, setHeader ] = useState('');
  const [ footer, setFooter ] = useState('');
  const [ origHeader, setOrigHeader ] = useState('');
  const [ origFooter, setOrigFooter ] = useState('');

  const reloadHeaderAndFooter = () => {
    // Load default values for header and footer.
    let allUserSettings = {};
    Object.values(themeJson.templates.default).forEach(el => {
      if ( el.type === 'footer' ) {
        allUserSettings[ 'footer' ] = el.values;
      } else if ( el.type === 'header' ) {
        allUserSettings[ 'header' ] = el.values;
      }
    })

    // Override default values with user defined values.
    let allUserSettingsTmp = userData;
    if ( typeof allUserSettingsTmp[ 'header' ] !== 'undefined' ) {
      Object.keys(allUserSettingsTmp[ 'header' ]).forEach(key => {
        allUserSettings[ 'header' ][ key ] = allUserSettingsTmp[ 'header' ][ key ];
      })
    }
    if ( typeof allUserSettingsTmp[ 'footer' ] !== 'undefined' ) {
      Object.keys(allUserSettingsTmp[ 'footer' ]).forEach(key => {
        allUserSettings[ 'footer' ][ key ] = allUserSettingsTmp[ 'footer' ][ key ];
      })
    }

    let headerTmp = window.structuredClone(origHeader);
    let footerTmp = window.structuredClone(origFooter);

    // Load header settings into the header element.
    if ( typeof allUserSettings[ 'header' ] !== 'undefined' ) {
      // Replace boolean values.
      Object.keys(allUserSettings[ 'header' ]).forEach(settingName => {
        // eslint-disable-next-line no-useless-escape
        let matches = headerTmp.match(new RegExp("{/header." + settingName + "}(\[\\S\\s\]*?){header." + settingName + "/}", "m"));
        if ( matches !== null ) {
          // eslint-disable-next-line no-useless-escape
          headerTmp = headerTmp.replaceAll(new RegExp("{/header." + settingName + "}(\[\\S\\s\]*?){header." + settingName + "/}", "gm"), allUserSettings[ 'header' ][ settingName ] ? matches[ 1 ] : '');
        }
      })

      Object.keys(allUserSettings[ 'header' ]).forEach(settingName => {
        headerTmp = headerTmp.replaceAll('{header.' + settingName + '}', typeof allUserSettings[ 'header' ][ settingName ] === 'string' ? allUserSettings[ 'header' ][ settingName ].replaceAll("\n", '<br/>') : allUserSettings[ 'header' ][ settingName ]);
      })
    }

    // Load footer settings into the footer element.
    if ( typeof allUserSettings[ 'footer' ] !== 'undefined' ) {
      // Replace all boolean logic.
      Object.keys(allUserSettings[ 'footer' ]).forEach(settingName => {
        // eslint-disable-next-line no-useless-escape
        let matches = footerTmp.match(new RegExp("{\/footer." + settingName + "}(\[\\S\\s\]*?){footer." + settingName + "\/}", "m"));
        if ( matches !== null ) {
          // eslint-disable-next-line no-useless-escape
          footerTmp = footerTmp.replaceAll(new RegExp("{\/footer." + settingName + "}(\[\\S\\s\]*?){footer." + settingName + "\/}", "gm"), allUserSettings[ 'footer' ][ settingName ] ? matches[ 1 ] : '');
        }
      })

      Object.keys(allUserSettings[ 'footer' ]).forEach(settingName => {
        footerTmp = footerTmp.replaceAll('{footer.' + settingName + '}', typeof allUserSettings[ 'footer' ][ settingName ] === 'string' ? allUserSettings[ 'footer' ][ settingName ].replaceAll("\n", '<br/>') : allUserSettings[ 'footer' ][ settingName ]);
      })
    }

    // Load header Neto example values.
    // Replace all Neto output B@SE code with example values, and hide all logic B@SE code.
    headerTmp = headerTmp.replace(/\[%[\S\s]*?%]/gm, '').replace(/\[@(.*?)@]/gm, (a, b) => {
      return getExampleValue(themeJson, 'header', b, typeof allUserSettings[ 'header' ] !== 'undefined' ? allUserSettings[ 'header' ] : {});
    });

    // Load footer Neto example values.
    // Replace all Neto output B@SE code with example values, and hide all logic B@SE code.
    footerTmp = footerTmp.replace(/\[%[\S\s]*?%]/gm, '').replace(/\[@(.*?)@]/gm, (a, b) => {
      return getExampleValue(themeJson, 'footer', b, typeof allUserSettings[ 'header' ] !== 'undefined' ? allUserSettings[ 'header' ] : {});
    });

    setHeader(headerTmp);
    setFooter(footerTmp);
  }

  const currentTheme = initData.tI;
  const currentStyle = initData.tSI;

  // Load theme settings on any template load.
  useEffect(() => {
    if ( themeJson === false ) {
      fetch('/themes/' + currentTheme + '/theme.json')
        .then((r) => r.json())
        .then(settings => {
          setThemeJson(settings);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ themeJson ]);

  // Load user data (user settings and template element layouts).
  useEffect(() => {
    if ( userData === false ) {
      getUserSettings().then(settings => {
        if ( typeof settings.status !== 'undefined' ) {
          alert('Error loading user settings: ' + settings.message)
          setIsError(true);
        } else {
          setUserData(settings);
        }
      });
    }
  }, [ userData ])

  // Load the template elements on template change.
  useEffect(() => {
    // Wait for user data and theme data loading to finish.
    if ( userData === false || themeJson === false ) {
      return;
    }

    // Only run if we change the template, otherwise userData will make an infinite loop.
    if ( window.currentTemplate === currentTemplate ) {
      return;
    }

    // Expose current template to global so we can use utility functions.
    window.currentTemplate = currentTemplate;
    window.themeJson = themeJson;

    // Load sidebar elements on each template change if we don't have any saved data.
    if ( Object.keys(userData).length === 0 || typeof userData[ currentTemplate ] === 'undefined' ) {
      // Initialize an unique ID for each element and load template elements.
      let elements = themeJson.templates[ currentTemplate ];
      elements.forEach((element, i) => {
        let elementDefaults    = getElementByType(element.type);
        elementDefaults.id     = createUniqueElementId();
        elementDefaults.values = {};

        // Load any global defaults for the element in the theme.
        if ( typeof themeJson.templates[ 'default' ] !== "undefined" ) {
          Object.values(themeJson.templates[ 'default' ]).forEach(el => {
            if ( el.type === element.type ) {
              if ( typeof el.values !== 'undefined' ) {
                Object.keys(el.values).forEach(key => {
                  elementDefaults.values[ key ] = el.values[ key ];
                })
              }
            }
          })
        }

        // Load any template specific defaults.
        if ( typeof element.values !== 'undefined' ) {
          Object.keys(element.values).forEach(key => {
            elementDefaults.values[ key ] = element.values[ key ];
          })
        }

        elements[ i ] = elementDefaults;
      })
      setCurrentElements(elements);
      saveCurrentElements(elements, null, userData);

      let ud                = Object.assign({}, userData);
      ud[ currentTemplate ] = elements;
      setUserData(ud);
    } else {
      // Load current elements if we have any saved data.
      if ( typeof userData[ currentTemplate ] !== 'undefined' ) {
        let elementsMinimal = userData[ currentTemplate ];
        let elements        = [];
        elementsMinimal.forEach(el => {
          let elD    = getElementByType(el.type, currentTemplate);
          elD.values = el.values;
          elD.id     = el.id;
          elements.push(elD);
        })

        setCurrentElements(elements);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentTemplate, themeJson, userData ]);

  // Save current element data on each element edit.
  useEffect(() => {
    if ( userData === false ) {
      return;
    }

    // Expose current elements to global.
    window.currentElements = currentElements;

    // Skip if it's not initialized yet.
    if ( Object.keys(currentElements).length === 0 ) {
      return;
    }

    saveCurrentElements(currentElements, null, userData);

    let ud                = Object.assign({}, userData);
    ud[ currentTemplate ] = currentElements;
    setUserData(ud);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentElements ]);

  const handleTabChange = (event, newValue) => {
    setIsMobilePreview(newValue);
  };

  const scrollBack = (elementId) => {
    let iframe = document.getElementById('previewIframe');
    let element = iframe.contentWindow.document.getElementById('el-' + elementId);
    if (element){
      let elementOffset  = element.offsetTop;

      iframe.addEventListener("load", function () {
        iframe.contentWindow.document.documentElement.scrollTo({
          top: elementOffset - 200,
        });
      });
    }
  }

  const setCurrentSettingsPartial = (elementId, settingId, value) => {
    let tmp = [ ...currentElements ];
    tmp.forEach((element, i) => {
      if ( element.id === elementId ) {
        element.values[ settingId ] = value;
        tmp[ i ]                    = element;
      }
    })
    setCurrentElements(tmp);
    scrollBack(elementId);
  }

  return isError ? (<div style={{
    width: '100%',
    fontSize: 18,
    textAlign: 'center',
    paddingTop: 300,
  }}>Please try again later or contact support</div>) : (
    <div className="App">
      <Header
        onTabChange={handleTabChange}
        isMobilePreview={isMobilePreview}
        currentTemplate={currentTemplate}
        setCurrentTemplate={setCurrentTemplate}
        setIsThemeStyleOpen={setIsThemeStyleOpen}
        isThemeStyleOpen={isThemeStyleOpen}
        setThemeSettings={setThemeSettings}
        isElementEditorOpen={isElementEditorOpen}
        setIsElementEditorOpen={setIsElementEditorOpen}
        currentElement={elementEditorElement}
        isThemeSettingsOpen={isThemeSettingsOpen}
        setIsThemeSettingsOpen={setIsThemeSettingsOpen}
        themeSettings={themeSettings}
        isElementPickerOpen={isElementPickerOpen}
        setIsElementPickerOpen={setIsElementPickerOpen}
        currentElements={currentElements}
        currentTheme={currentTheme}
        currentStyle={currentStyle}
        currentThemeName={initData.tN}
        currentStyleName={initData.tS}
        themeJson={themeJson}
        setThemeJson={setThemeJson}
        userData={userData}
        setUserData={setUserData}
        reloadHeaderAndFooter={reloadHeaderAndFooter}
        tabValue={tabValue}
        setTabValue={setTabValue}
        isTrial={initData.iT}
      />
      <div className="wrapper">
        {isThemeStyleOpen ?
          <ThemeStyleSettings
            setIsThemeStyleOpen={setIsThemeStyleOpen}
          />
          :
          <Sidebar
            currentElements={currentElements}
            templateElements={typeof elementsPerTemplate[ currentTemplate ] !== "undefined" ? elementsPerTemplate[ currentTemplate ] : []}
            commonElements={commonElements}
            setCurrentElements={setCurrentElements}
            currentTemplate={currentTemplate}
            defaultTemplateElements={elementsPerTemplate[ currentTemplate ]}
            setIsThemeStyleOpen={setIsThemeStyleOpen}
            isThemeSettingsOpen={isThemeSettingsOpen}
            setIsThemeSettingsOpen={setIsThemeSettingsOpen}
            value={tabValue}
            setValue={setTabValue}
            themeSettings={themeSettings}
            setThemeSettings={setThemeSettings}
            themeJson={themeJson}
            isElementEditorOpen={isElementEditorOpen}
            setIsElementEditorOpen={setIsElementEditorOpen}
            elementEditorElement={elementEditorElement}
            setElementEditorElement={setElementEditorElement}
            isElementPickerOpen={isElementPickerOpen}
            setIsElementPickerOpen={setIsElementPickerOpen}
            setCurrentSettings={setCurrentSettingsPartial}
            setCurrentTemplate={setCurrentTemplate}
            reloadHeaderAndFooter={reloadHeaderAndFooter}
            userData={userData}
            setUserData={setUserData}
          />
        }

        <Preview
          isMobilePreview={isMobilePreview}
          currentElements={currentElements}
          currentTemplate={currentTemplate}
          setIsElementEditorOpen={setIsElementEditorOpen}
          setElementEditorElement={setElementEditorElement}
          setIsElementPickerOpen={setIsElementPickerOpen}
          theme={currentTheme}
          themeStyle={currentStyle}
          themeJson={themeJson}
          setThemeJson={setThemeJson}
          themeSettings={themeSettings}
          setThemeSettings={setThemeSettings}
          setIsThemeSettingsOpen={setIsThemeSettingsOpen}
          header={header}
          footer={footer}
          setOrigHeader={setOrigHeader}
          setOrigFooter={setOrigFooter}
          reloadHeaderAndFooter={reloadHeaderAndFooter}
          userData={userData}
          setUserData={setUserData}
        />
      </div>
    </div>

  );
}

export default Editor;
