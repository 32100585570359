import elementsPerTemplate from "./themeData/dawn";
import commonElements from "./themeData/common";

export const isStaging = /dev\.prettyemails/i.test(document.location.hostname) || process.env.NODE_ENV === "staging";
export const isLocal   = /localhost/i.test(document.location.hostname) || process.env.NODE_ENV === "local";

/**
 * Returns a default element instance of an element for the current theme & template based on its type.
 *
 * @param {string} type The element type to get.
 * @param {?string} currentTemplate The current template override.
 *
 * @return {null|Object} The element or null if not found.
 */
export const getElementByType = (type, currentTemplate = null) => {
  let foundElement = null;

  if ( currentTemplate === null ) {
    currentTemplate = window.currentTemplate;
  }

  [ ...elementsPerTemplate[ currentTemplate ], ...commonElements ].forEach(element => {
    if ( element.type === type ) {
      foundElement = { ...element };
      foundElement.values = {};

      let defaultElements = window.themeJson.templates[ currentTemplate ];

      // Load any global defaults for the element in the theme.
      if ( typeof window.themeJson.templates[ 'default' ] !== "undefined" ) {
        Object.values(window.themeJson.templates[ 'default' ]).forEach(el => {
          if ( el.type === foundElement.type ) {
            if ( typeof el.values !== 'undefined' ) {
              Object.keys(el.values).forEach(key => {
                foundElement.values[ key ] = el.values[ key ];
              })
            }
          }
        })
      }
      defaultElements.forEach(defaultElement => {
        if (defaultElement.type === foundElement.type) {
          // Load any template specific defaults.
          Object.keys(defaultElement.values).forEach(key => {
            foundElement.values[ key ] = defaultElement.values[ key ];
          })

        }
      })
    }
  })

  return foundElement;
}

/**
 * Creates an unique element ID without ID collision.
 *
 * @return {string} The unique element ID.
 */
export const createUniqueElementId = () => {
  let haveCollision = false;
  let firstRun      = true;
  let id            = '';
  while ( firstRun || haveCollision ) {
    firstRun = false;

    id = _getRandomString();
    if ( typeof window.currentElements !== 'undefined' && Array.isArray(window.currentElements) ) {
      // eslint-disable-next-line no-loop-func
      window.currentElements.forEach(element => {
        if ( element.id === id ) {
          haveCollision = true;
        }
      })
    }
  }

  return id;
}

/**
 * Generates a random string containing 5 characters.
 *
 * @returns {string} The random string.
 * @private
 */
function _getRandomString() {
  return Math.random().toString(36).slice(2, 7);
}

/**
 * Retrieves saved user settings from the data storage for the current template.
 */
export const getUserSettings = async () => {
  let data = null;
  let appData = getInitData();

  if (isLocal){
    data = JSON.parse(window.sessionStorage.getItem('userElementsData'));
  }

  if ( ! data && appData.s !== null ) {
    data = await fetch('https://' + (isStaging || isLocal ? 'dev.' : '') + 'api.outsmart.digital/pretty_emails/settings', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + appData.s,
      }
    }).then(res => res.json()).then(res => {
      if ( res.status === 'error' ) {
        return res;
      }

      return res.settings;
    });

    if ( typeof data.status !== 'undefined' ) {
      return data;
    }
  }

  if ( ! data || (Array.isArray(data) && data.length === 0) ) {
    return {};
  }

  return data;
};

/**
 * Saves user settings to the data storage.
 */
export const saveCurrentElements = (elements, currentTemplate = null, userData) => {
  if ( currentTemplate === null ) {
    currentTemplate = window.currentTemplate;
  }

  // Remove any unneeded keys from the element object.
  let elementsMinimal = [];
  elements.forEach(element => {
    elementsMinimal.push({
      id: element.id,
      type: element.type,
      values: element.values,
    })
  })

  userData[ currentTemplate ] = elementsMinimal;

  if (isLocal){
    window.sessionStorage.setItem('userElementsData', JSON.stringify(userData));
  }

  let appData = getInitData();
  if ( appData.s !== null ) {
    // Save into settings database as well.
    fetch('https://' + (isStaging || isLocal ? 'dev.' : '') + 'api.outsmart.digital/pretty_emails/settings', {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + appData.s,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then(res => res.json())
  }
}

/**
 * Saves a global element data into data storage.
 *
 * @param {string} elementId The element ID of which data to save.
 * @param {Object<string, *>} elementData The data to save.
 * @param {Object} userData The local settings.
 *
 * @return {Promise|null} The fetch object or null if we can't make a request.
 */
export const saveGlobalElement = (elementId, elementData, userData) => {
  userData[ elementId ] = elementData;

  if (isLocal){
    window.sessionStorage.setItem('userElementsData', JSON.stringify(userData));
  }

  let appData = getInitData();
  if ( appData.s !== null ) {
    // Save into settings database as well.
    return fetch('https://' + (isStaging || isLocal ? 'dev.' : '') + 'api.outsmart.digital/pretty_emails/settings', {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + appData.s,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then(res => res.json())
  }

  return new Promise((resolve, reject) => {
    resolve();
  });
}

/**
 * Gets current PrettyEmails initialization data.
 *
 * @returns {null|{s: null, tI: string, tN: string, tSI: string, tS: string}}
 */
export const getInitData = () => {
  let url  = new URL(window.location.href);
  let data = url.searchParams.get("d");

  // Hardcode values if we are on development. TODO - add a theme chooser in header where current theme name is now.
  if ( ! data && isLocal ) {
    return {
      s: null,
      tN: 'Dawn',
      tI: 'dawn',
      tS: 'Morning',
      tSI: 'default',
      iT: false,
    }
  }

  data = atob(data);
  if ( ! data ) {
    return null;
  }

  try {
    data = JSON.parse(data);
    if ( ! data ) {
      return null;
    }
  } catch ( e ) {
    console.error(e);
    return null;
  }

  return data || null;
}

/**
 * "Simple" MD5 function.
 *
 * @param {string} str The string to hash.
 *
 * @return {string} The hash.
 */
export function hashCode(str) {
  let hash = 0;
  for ( let i = 0, len = str.length; i < len; i ++ ) {
    let chr = str.charCodeAt(i);
    hash    = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Returns the value for display instead of Neto B@SE.
 *
 * @param {Object} themeJson The theme settings data.
 * @param {string} elementType The element type.
 * @param {string} fieldId The Neto B@SE field to replace.
 * @param {Object.<string, *>} elementSettings The element settings data, if any.
 *
 * @return {string} The example value to show.
 */
export const getExampleValue = (themeJson, elementType, fieldId, elementSettings = {}) => {
  let currentTemplate = window.currentTemplate;

  if ( typeof themeJson.exampleValues[ currentTemplate ] === 'undefined' || typeof themeJson.exampleValues[ currentTemplate ][ elementType ] === 'undefined' || typeof themeJson.exampleValues[ currentTemplate ][ elementType ][ fieldId ] === 'undefined' ) {
    // Check if there is a default value defined for all templates.
    if ( typeof themeJson.exampleValues[ 'default' ] !== 'undefined' && typeof themeJson.exampleValues[ 'default' ][ elementType ] !== 'undefined' && typeof themeJson.exampleValues[ 'default' ][ elementType ][ fieldId ] !== 'undefined' ) {
      return themeJson.exampleValues[ 'default' ][ elementType ][ fieldId ];
    } else {
      return 'Example Value';
    }
  }

  let value = themeJson.exampleValues[ currentTemplate ][ elementType ][ fieldId ];

  // Load variables in example values.
  if ( typeof value === 'string' ) {
    Object.keys(elementSettings).forEach(key => {
      value = value.replaceAll('${' + elementType + '.' + key + '}', elementSettings[ key ]);
    });
  }

  return value;
};
