import React, { useEffect, useState } from "react";
import SiteLinksSettings from "./theme-settings/SiteLinksSettings";
import SocialSettings from "./theme-settings/SocialSettings";
import FooterSettings from "./theme-settings/FooterSettings";
import { getExampleValue } from "../../../utilities";
import LogoSettings from "./theme-settings/LogoSettings";

function Preview({
  isMobilePreview,
  currentElements,
  currentTemplate,
  setElementEditorElement,
  setIsElementEditorOpen,
  setIsElementPickerOpen,
  theme,
  themeStyle: style,
  setThemeSettings,
  themeJson,
  setIsThemeSettingsOpen,
  header,
  footer,
  setOrigHeader,
  setOrigFooter,
  reloadHeaderAndFooter,
  userData,
  setUserData,
}) {
  /**
   * @var {boolean} True if we are ready to render the whole preview.
   */
  const [ isReady, setIsReady ] = useState(false);

  /**
   * @var {Object.<string, string>} The load status of each element.
   * Possible statuses:
   * - "pending-load" - waiting for fetch
   * - "loading" - is currently fetching
   * - "error" - load error
   */
  const [ loadData, setLoadData ] = useState({});

  /**
   * @var {array} Array of HTML strings, ready to be rendered.
   */
  const [ elementsHtml, setElementsHtml ] = useState([]);

  /**
   * @var {Object.<string, string>>} Holds raw element HTML by element ID.
   */
  const [ elementsCache, setElementsCache ] = useState({});

  // Load header.
  loadHeader();
  // Load footer.
  loadFooter();

  /**
   * Load header into state.
   */
  function loadHeader() {
    if ( header !== '' ) {
      return;
    }

    loadCommon('header').then(h => {
      setOrigHeader(h);
    });
  }

  /**
   * Load footer into state.
   */
  function loadFooter() {
    if ( footer !== '' ) {
      return;
    }

    loadCommon('footer').then(h => {
      setOrigFooter(h);
    });
  }

  /**
   * Loads a common template file, specified by name.
   *
   * @param {string} name The filename to load.
   * @return {Promise<string>}
   */
  async function loadCommon(name) {
    return await fetch('/themes/' + theme + '/' + name + '.html')
      .then((r) => r.text())
      .then(text => {
        return text;
      })
  }

  /**
   * Renders the loading screen based on load status data.
   *
   * @return {string} The loader HTML.
   */
  const renderLoader = () => {
    let totalElementsToLoad    = Object.keys(loadData).length;
    let numberOfLoadedElements = 0;
    Object.values(loadData).forEach(val => {
      if ( val === 'loaded' ) {
        numberOfLoadedElements ++;
      }
    })

    return `<div>Loaded ${numberOfLoadedElements}/${totalElementsToLoad}...</div>`;
  }

  /**
   * Extract load data status for asynchronous load state updating.
   */
  useEffect(() => {
    if ( typeof window.elementsCache === 'undefined' ) {
      window.elementsCache = {};
    }

    // noinspection JSUnresolvedFunction
    window.loadData = window.structuredClone(loadData);
    setElementsCache(window.elementsCache);
  }, [ loadData ])

  // Reset load data on template change.
  useEffect(() => {
    window.isChangingTemplate = true;
  }, [ currentTemplate ])

  /**
   * Loads the element HTML from the template file or core element file.
   *
   * @param {string} elementType The element type to load.
   */
  const loadElement = elementType => {
    let responseCode = null;

    const checkLoad = res => {
      // Detect server error or not found.
      if ( responseCode > 400 ) {
        throw new Error('Response code for template-specific element "' + elementType + '" is ' + responseCode);
      }

      // Detect not found.
      if ( res.match(/bundle\.js/) ) {
        throw new Error('Not element HTML for template-specific element load! ("' + elementType + '")');
      }

      // Save element HTML into cache.
      window.elementsCache[ elementType ] = res;

      // Save load status.
      window.loadData[ elementType ] = 'loaded';
      setLoadData(window.loadData);

      // Clear loading flag.
      delete window.isLoadingData[ elementType ];
    }

    fetch('/themes/' + theme + '/elements/' + currentTemplate + '/' + elementType + '/' + style + '.html').then(res => {
      responseCode = res.status;
      return res.text();
    }).then(checkLoad).catch(e => {
      fetch('/themes/' + theme + '/elements/core/' + elementType + '/' + style + '.html').then(res => {
        responseCode = res.status;
        return res.text();
      }).then(checkLoad).catch(e => {
        console.error(e);
        // noinspection JSUnresolvedFunction
        window.loadData[ elementType ] = 'error';
        setLoadData(window.loadData);

        // Clear loading flag.
        delete window.isLoadingData[ elementType ];
      })
    })
  }

  // Load elements that are not loaded.
  useEffect(() => {
    // Initialize loading tracker.
    if ( typeof window.isLoadingData === 'undefined' ) {
      window.isLoadingData = {};
    }

    Object.keys(loadData).forEach(elementType => {
      if ( typeof window.isLoadingData[ elementType ] !== "undefined" && window.isLoadingData[ elementType ] === true ) {
        return;
      }

      if ( loadData[ elementType ] === 'pending-load' ) {
        window.isLoadingData[ elementType ] = true;
        loadElement(elementType);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loadData ])

  // Checks if there are any elements to load, remove, or reload.
  useEffect(() => {
    let currentElementTypes = [];

    // Start fresh if we are changing the template.
    if ( window.isChangingTemplate ) {
      window.loadData           = {};
      window.elementsCache      = {};
      window.isLoadingData      = {};
      window.isChangingTemplate = false;
    }

    /*
     * 1. Load elements that are not loaded.
     */

    currentElements.forEach(element => {
      currentElementTypes.push(element.type);
      if ( typeof loadData[ element.type ] === 'undefined' ) {
        window.loadData[ element.type ] = 'pending-load';
        setIsReady(false);
      }
    })

    /*
     * 2. Remove elements that were removed.
     */

    Object.keys(elementsCache).forEach(elementType => {
      if ( currentElementTypes.indexOf(elementType) === - 1 ) {
        delete window.elementsCache[ elementType ];
        setElementsCache(window.elementsCache);

        delete window.loadData[ elementType ];
        setLoadData(window.loadData);
      }
    })

    setLoadData(window.loadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentElements, currentTemplate ]);

  // Render if all elements are loaded.
  useEffect(() => {
    let allLoaded = true;
    Object.values(loadData).forEach(status => {
      if ( status !== 'loaded' ) {
        allLoaded = false;
      }
    })

    // Exit if not all elements are loaded.
    if ( Object.keys(loadData).length === 0 || ! allLoaded ) {
      return;
    }

    let elementsHtmlTmp = [];
    currentElements.forEach((el, i) => {
      let elementType = el.type;
      let elementId   = el.id;
      let html        = elementsCache[ elementType ];

      if ( ! el ) {
        console.error('Could not load element type "' + elementType + '" (ID: ' + el.id + ') for preview.')
        return;
      }

      if ( ! html ) {
        console.error('Could not load element cache "' + elementType + '" (ID: ' + el.id + ') for preview.')
        return;
      }

      window[ 'elementClickHandler_' + i ] = () => {
        setIsThemeSettingsOpen(false);
        setIsElementPickerOpen(false);
        setElementEditorElement(el);
        setIsElementEditorOpen(true);
      }

      window[ 'elementClickHandler_header' ] = () => {
        setIsElementEditorOpen(false);
        setIsElementPickerOpen(false);
        setThemeSettings({
          component: <LogoSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
          label: "Logo"
        })
        setIsThemeSettingsOpen(true);
      }

      window[ 'elementClickHandler_footer' ] = ($element) => {
        setIsElementEditorOpen(false);
        setIsElementPickerOpen(false);

        if ( $element === 'site-links' ) {
          setThemeSettings({
            component: <SiteLinksSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
            label: "Site links"
          });
          setIsThemeSettingsOpen(true);
        }

        if ( $element === 'social' ) {
          setThemeSettings({
            component: <SocialSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
            label: "Social"
          });
          setIsThemeSettingsOpen(true);
        }

        if ( $element === 'store-info' ) {
          setThemeSettings({
            component: <FooterSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
            label: "Store info / footer"
          });
          setIsThemeSettingsOpen(true);
        }

      }

      // Insert element ID into element.
      html = html.replaceAll('{elementId}', 'el-' + elementId);
      // Replace element variables with the user settings or defaults.
      if ( typeof el.values !== 'undefined' ) {
        let defaultElement = themeJson.templates[currentTemplate].find(element => element.type === elementType);
        if (typeof defaultElement === "undefined" || !defaultElement){
          defaultElement = themeJson.templates["default"].find(element => element.type === elementType);
        }
        // Replace all boolean logic.
        Object.keys(defaultElement.values).forEach(name => {
          // eslint-disable-next-line no-useless-escape
          let matches = html.match(new RegExp("{\/" + elementType + "\." + name + "}(\[\\S\\s\]*?){" + elementType + "." + name + "\/}", "m"));
          if ( matches !== null ) {
            // eslint-disable-next-line no-useless-escape
            html = html.replaceAll(new RegExp("{\/" + elementType + "\." + name + "}(\[\\S\\s\]*?){" + elementType + "." + name + "\/}", "gm"), (typeof el.values[name]  !== 'undefined' ? el.values[ name ] : defaultElement.values[name]) ? matches[ 1 ] : '');
          }
        })

        // Replace regular values.
        Object.keys(defaultElement.values).forEach(name => {
          if (typeof el.values[ name ] !== "undefined"){
            html = html.replaceAll('{' + elementType + '.' + name + '}', typeof el.values[ name ] === 'string' ? el.values[ name ].replaceAll("\n", '<br/>') : el.values[ name ]);
          } else {
            html = html.replaceAll('{' + elementType + '.' + name + '}', typeof defaultElement.values[name] === 'string' ? defaultElement.values[name].replaceAll("\n", '<br/>') : defaultElement.values[name]);
          }
        });
      }

      // Hide all unused element variables
      // eslint-disable-next-line no-useless-escape
      html = html.replace(new RegExp("{" + elementType + "\..*?}", "gm"), '');

      // Hide all PARAM fields.
      html = html.replace(/\[%PARAM \*format[\S\s]*?END PARAM%]/gm, '');

      // Replace all Neto output B@SE code with example values, and hide all logic B@SE code.
      html = html.replace(/\[%[\S\s]*?%]/gm, '').replace(/\[@(.*?)@]/gm, (a, b) => {
        return getExampleValue(themeJson, elementType, b, typeof el.values !== 'undefined' ? el.values : {});
      });

      elementsHtmlTmp.push('<div class="elementBorder ' + elementType + ' elementBorder' + elementId + '" onclick="window.parent[\'elementClickHandler_' + i + '\']()">' + html + '</div>');
    })

    reloadHeaderAndFooter();

    setElementsHtml(elementsHtmlTmp);
    setIsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loadData, currentElements ])

  return (
    <div id="previewWrapper">
      <div className="preview">
        <iframe
          title={"Preview"}
          id={"previewIframe"}
          srcDoc={isReady ? header + '<style>.elementBorder{border: 2px solid transparent; cursor: pointer; margin: -2px;} .elementBorder:hover { border: 2px solid rgba(25, 118, 210, 0.5) !important; border-radius: 3px;}</style>' + elementsHtml.join('') + footer : renderLoader()}
          style={{
            border: "none",
            width: isMobilePreview ? "375px" : "100%",
            height: "100%",
            //paddingBottom: "20px",
            transition: "width 0.17s ease 0s"
          }}/>
      </div>
    </div>
  );
}

export default Preview;
