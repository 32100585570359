import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const PaymentInformation = {
  type: "payment-information",
  name: "Payment information",
  icon: <AttachMoneyIcon/>,
  properties: [
    {
      name: "Payment Information",
      content: [
        {
          id: "payment-info",
          label: "Payment info",
          type: "input",
        },
      ]
    },
  ],
  values: {
    "payment-info": "Payment Info",
  },
};

export default PaymentInformation;
