import ContactMailIcon from "@mui/icons-material/ContactMail";

const AddressDetails = {
	type: "address-details",
	name: "Address Details",
	icon: <ContactMailIcon/>,
	properties: [
		{
			name: "Address Details",
			content: [
				{
					id: "customer-heading",
					label: "Customer Heading",
					type: "input",
				},
				{
					id: "shipping-address-heading",
					label: "Shipping Address Heading",
					type: "input",
				},
				{
					id: "show-shipping-and-billing-numbers",
					label: "Show shipping and billing phone numbers",
					type: "checkbox",
				},
				{
					id: "text-before-phone-number",
					label: "Text before phone number",
					type: "input",
				},
				{
					id: "show-customer-email",
					label: "Show customer email",
					type: "checkbox",
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			],
		},
	],
	values: {
		"customer-heading": "Customer",
		"shipping-address-heading": "Shipping Address",
		"show-shipping-and-billing-numbers": true,
		"text-before-phone-number": "",
		"show-customer-email": true,
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default AddressDetails;
