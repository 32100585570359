import InfoIcon from '@mui/icons-material/Info';

const CheckProductButton = {
  type: "check-product-button",
  name: "Check Product Button",
  icon: <InfoIcon/>,
  properties: [
    {
      name: "Button",
      content: [
        {
          id: "text-above-button",
          label: "Text above button",
          type: "textBox",
        },
        {
          id: "button-text",
          label: "Button text",
          type: "input",
        },
        {
          id: "links-to",
          label: "Links to",
          type: "input"
        },
        {
          id: "text-below-button",
          label: "Text below button",
          type: "textBox",
        },
        {
          id: "button-color",
          label: "Button color",
          type: "colorPicker",
        },
        {
          id: "button-text-color",
          label: "Button text color",
          type: "colorPicker",
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
  ],
  values: {
    "text-above-button": "",
    "button-text": "",
    "links-to": "",
    "text-below-button": "",
    "button-color": "#f64c3f",
    "button-text-color": "#ffffff",
    "text-align": "center",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10
  },
};

export default CheckProductButton;
