import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Slide from "@mui/material/Slide";

function ThemeStyleHeader({
  currentElement,
  setIsElementEditorOpen,
  isElementEditorOpen,
}) {

  return (
    <Slide direction="right" in={isElementEditorOpen}>
      <div className="headerBackButton">
        <div
          className="heading"
          onClick={
            () => {
              setIsElementEditorOpen(false);
            }
          }
        >
          <ArrowBackIosIcon className="arrowBackIcon"/>
          <b>{currentElement.name}</b>
        </div>
      </div>
    </Slide>
  );
}

export default ThemeStyleHeader;