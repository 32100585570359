import React, { useState } from "react";
import { Button, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";

function SendTestPopup({
  popupClose,
  currentTemplate,
  isSendTestPopupOpen,
}) {

  const [ email, setEmail ] = useState('');
  // TODO remove the following comment when the variable below is used
  // eslint-disable-next-line no-unused-vars
  const apiAction = 'test';
  const html = useState(document.querySelector('#previewWrapper iframe').srcdoc);


  let templateName = currentTemplate.replace('-', ' ');
  templateName = templateName.charAt(0).toUpperCase() + templateName.slice(1);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const requestOptions = {
    method: 'POST',
    mode: 'no-cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ content: html, template: currentTemplate.replace("-", ' ').toUpperCase() })
  };

  function fetchAPI() {
    fetch('https://dev.prettyemails.outsmart.digital/api/send-test.php?email=' + email, requestOptions)
      .then( resp => resp.json())
      .then((result) => {
        return result;
      });
  }

  return(
    <Fade
      in={isSendTestPopupOpen}
      timeout={600}
      style={{ transitionDelay:'100ms'}}
    >
      <div className="popupWrapper" onClick={e=>{
        if (e.target.classList.contains('popupWrapper')){
          popupClose();
        }
        e.stopPropagation();
        return false;
      }}>
        <Paper className="popup">
          <div className="popupElementsWrapper">
            <div className="popupHeading">
              <h2 style={{marginTop: 0}}>Send Test - {templateName}</h2>
              <CloseIcon
                onClick={popupClose}
                className="closeButton"
              />
            </div>
            <div className="popupBody">
              <p
                style={{margin: 0, fontWeight: 600,}}
              >Email address</p>
              <TextField
                onChange={handleEmailChange}
                id="email"
                label="Email"
                style={{
                  margin: "10px 0",
                  width: "100%",
                }}
              />
            </div>
            <div className="popupFooter">
              <Button
                style={{
                  color: '#3b3b3b',
                  border: '1px solid #9b9b9b',
                  margin: "10px 5px",
                }}
                onClick={popupClose}
                variant="outlined"
                size="small"
              >
                Close
              </Button>
              <Button
                style={{
                  border: '1px solid #9b9b9b',
                  margin: "10px 5px",
                }}
                variant="contained"
                size="small"
                onClick={fetchAPI}
              >
                Send test
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </Fade>
  );
}

export default SendTestPopup;
