import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { getUserSettings, saveGlobalElement } from "../../../../utilities";
import {Switch} from "@material-ui/core";
import ColorPicker from "../ColorPicker";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

function SiteLinksSettings({ reloadHeaderAndFooter, themeJson, userData, setUserData }) {
  const [ values, setValues ]                     = useState(null);
  const [ isShowLinkActive, setIsShowLinkActive ] = useState(false);
  const [ isLocalFontSettingsActive, setIsLocalFontSettingsActive ] = useState(false);
  let themeJsonDefaultValue = typeof themeJson.templates["header"] !== "undefined" ? themeJson.templates["header"].values : themeJson.templates['default'].find(e => e.type === "header").values;

  useEffect(() => {
    setIsShowLinkActive(typeof values[ 'site-link-show-site-links' ] === 'undefined' ? false : values[ 'site-link-show-site-links' ]);
    setIsLocalFontSettingsActive(typeof values[ 'site-links-local-font-settings-activated' ] === 'undefined' ? false : values[ 'site-links-local-font-settings-activated' ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // todo for sometime in future - put this into useEffect.
  if ( values === null ) {
    let valuesTmp = {};
    Object.values(themeJson.templates.default).forEach(el => {
      if ( el.type === 'header' ) {
        valuesTmp = el.values;
      }
    })

    let userSettings = userData;
    if ( typeof userSettings[ 'header' ] !== 'undefined' ) {
      Object.keys(userSettings[ 'header' ]).forEach(settingName => {
        valuesTmp[ settingName ] = userSettings[ 'header' ][ settingName ];
      })

      setValues(valuesTmp);
      return;
    } else {
      setValues(valuesTmp);
      return;
    }
  }

  const handleChange = (event) => {
    // noinspection JSUnresolvedFunction
    let valuesTmp                = window.structuredClone(values);

    if ( event.value ){
      valuesTmp[ event.id ] = event.value;
    } else {
      if (event.target.id){
        valuesTmp[ event.target.id ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      } else {
        valuesTmp[ event.target.name ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      }
    }

    setValues(valuesTmp);
    saveGlobalElement('header', valuesTmp, userData).then(()=>{
      getUserSettings().then(res => setUserData(res));
    });
    reloadHeaderAndFooter();
  }

  const handleCheckboxChange = (event) => {
    setIsShowLinkActive(event.target.checked);
  }

  const handleLocalFontSettingsChange = (event) => {
    setIsLocalFontSettingsActive(event.target.checked);
  }

  return (
    <div className="settingsContainer">
      <div className="settingsSection">
        <p className="settingsHeading"><b>Site Links</b></p>
        <div className="settingsDescription">
          <p>
            Add common links to your website here, and they will be included in all emails.
          </p>
        </div>
        <FormControlLabel
          className={"elementOptionInput"}
          style={{
            marginBottom: "20px",
            width: "90%"
          }}
          control={
            <Switch
              id="site-link-show-site-links"
              checked={typeof values[ 'site-link-show-site-links' ] === 'undefined' ? themeJsonDefaultValue['site-link-show-site-links'] : values[ 'site-link-show-site-links' ]}
              onChange={(event) => {
                handleCheckboxChange(event);
                handleChange(event);
              }}
            />
          }
          label="Show site links"
        />
        <ColorPicker
            hex={typeof values[ 'site-links-background-color' ] === 'undefined' ? themeJsonDefaultValue['site-links-background-color'] : values[ 'site-links-background-color' ]}
            label="Site Links Background Color"
            id="site-links-background-color"
            setColor={handleChange}
        />
        <div className="settingsDescription">
          <p>
            This controls the padding above and below the selected element. If global padding is applied this will be overridden.
          </p>
        </div>
        <Box sx={{ width: "90%", }}>
          <Typography id="spacer-size" gutterBottom>
            Padding Spacing
          </Typography>
          <Slider
              name="site-links-spacing"
              onChange={handleChange}
              min={0}
              max={50}
              value={typeof values[ 'site-links-spacing' ] === 'undefined' ? themeJsonDefaultValue['site-links-spacing'] : values[ 'site-links-spacing' ]}
              aria-labelledby="site-links-spacing"
              valueLabelDisplay="auto"
          />
        </Box>
      </div>
      {isShowLinkActive &&
      <div>
        <div className="settingsSection">
          <p className="settingsHeading"><b>Local Settings</b></p>
          <FormControlLabel
              style={{
                width: "90%"
              }}
              control={
                <Switch
                    id="site-links-local-font-settings-activated"
                    checked={typeof values[ 'site-links-local-font-settings-activated' ] === 'undefined' ? themeJsonDefaultValue['site-links-local-font-settings-activated'] : values[ 'site-links-local-font-settings-activated' ]}
                    onChange={(event) => {
                      handleLocalFontSettingsChange(event);
                      handleChange(event);
                    }}
                />
              }
              label="Apply local font settings"/>
          {isLocalFontSettingsActive &&
          <div>
            <ColorPicker
                hex={typeof values[ 'site-links-font-color' ] === 'undefined' ? themeJsonDefaultValue['site-links-font-color'] : values[ 'site-links-font-color' ]}
                label="Font Color"
                className="textField"
                id="site-links-font-color"
                setColor={handleChange}
            />
            <Box style={{margin: "20px 0"}}>
              <Typography id="site-links-font-size">
                Font Size
              </Typography>
              <Slider
                  name="site-links-font-size"
                  id="site-links-font-size"
                  onChange={handleChange}
                  min={5}
                  max={35}
                  defaultValue={typeof values[ 'site-links-font-size' ] === 'undefined' ? themeJsonDefaultValue['site-links-font-size'] : values[ 'site-links-font-size' ]}
                  aria-labelledby="site-links-font-size"
                  valueLabelDisplay="auto"
              />
            </Box>
          </div>
          }
        </div>
        <div className="settingsSection">
          <p className="settingsHeading"><b>Link 1</b></p>
          <TextField
            className="textField"
            id="site-link-link1-text"
            label="Text"
            value={typeof values[ 'site-link-link1-text' ] === 'undefined' ? themeJsonDefaultValue['site-link-link1-text'] : values[ 'site-link-link1-text' ]}
            onChange={handleChange}
          />
          <TextField
            className="textField"
            id="site-link-link1-url"
            label="Link/URL"
            value={typeof values[ 'site-link-link1-url' ] === 'undefined' ? themeJsonDefaultValue['site-link-link1-url'] : values[ 'site-link-link1-url' ]}
            onChange={handleChange}
          />
        </div>
        <div className="settingsSection">
          <p className="settingsHeading"><b>Link 2</b></p>
          <TextField
            className="textField"
            id="site-link-link2-text"
            label="Text"
            value={typeof values[ 'site-link-link2-text' ] === 'undefined' ? themeJsonDefaultValue['site-link-link2-text'] : values[ 'site-link-link2-text' ]}
            onChange={handleChange}
          />
          <TextField
            className="textField"
            id="site-link-link2-url"
            label="Link/URL"
            value={typeof values[ 'site-link-link2-url' ] === 'undefined' ? themeJsonDefaultValue['site-link-link2-url'] : values[ 'site-link-link2-url' ]}
            onChange={handleChange}
          />
        </div>
        <div className="settingsSection">
          <p className="settingsHeading"><b>Link 3</b></p>
          <TextField
            className="textField"
            id="site-link-link3-text"
            label="Text"
            value={typeof values[ 'site-link-link3-text' ] === 'undefined' ? themeJsonDefaultValue['site-link-link3-text'] : values[ 'site-link-link3-text' ]}
            onChange={handleChange}
          />
          <TextField
            className="textField"
            id="site-link-link3-url"
            label="Link/URL"
            value={typeof values[ 'site-link-link3-url' ] === 'undefined' ? themeJsonDefaultValue['site-link-link3-url'] : values[ 'site-link-link3-url' ]}
            onChange={handleChange}
          />
        </div>
      </div>
      }
    </div>
  )
}

export default SiteLinksSettings;
