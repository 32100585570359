import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import { getUserSettings, saveGlobalElement } from "../../../../utilities";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Switch} from "@material-ui/core";
import ColorPicker from "../ColorPicker";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

function FooterSettings({ reloadHeaderAndFooter, themeJson, userData, setUserData }) {
  const [ values, setValues ]                     = useState(null);
  const [ isLocalFontSettingsActive, setIsLocalFontSettingsActive ] = useState(false);
  let themeJsonDefaultValue = typeof themeJson.templates["footer"] !== "undefined" ? themeJson.templates["footer"].values : themeJson.templates['default'].find(e => e.type === "footer").values;

  useEffect(() => {
    setIsLocalFontSettingsActive(typeof values[ 'store-info-local-font-settings-activated' ] === 'undefined' ? false : values[ 'store-info-local-font-settings-activated' ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollBack = (elementId) => {
    let iframe = document.getElementById('previewIframe');
    let element = iframe.contentWindow.document.getElementById(elementId);
    let elementOffset  = element.offsetTop;

    iframe.addEventListener("load", function () {
      iframe.contentWindow.document.documentElement.scrollTo({
        top: elementOffset,
      });
    });
  }

  // todo for sometime in future - put this into useEffect.
  if ( values === null ) {
    let valuesTmp = {};
    Object.values(themeJson.templates.default).forEach(el => {
      if ( el.type === 'footer' ) {
        valuesTmp = el.values;
      }
    })

    let userSettings = userData;
    if ( typeof userSettings[ 'footer' ] !== 'undefined' ) {
      Object.keys(userSettings[ 'footer' ]).forEach(settingName => {
        valuesTmp[ settingName ] = userSettings[ 'footer' ][ settingName ];
      })

      setValues(valuesTmp);
      return;
    } else {
      setValues(valuesTmp);
      return;
    }
  }

  const handleLocalFontSettingsChange = (event) => {
    setIsLocalFontSettingsActive(event.target.checked);
  }

  const handleChange = (event) => {
    // noinspection JSUnresolvedFunction
    let valuesTmp                = window.structuredClone(values);
    if ( event.value ){
      valuesTmp[ event.id ] = event.value;
    } else {
      if (event.target.id){
        valuesTmp[ event.target.id ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      } else {
        valuesTmp[ event.target.name ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      }
    }
    setValues(valuesTmp);

    saveGlobalElement('footer', valuesTmp, userData).then(()=>{
      getUserSettings().then(res => setUserData(res));
    });
    reloadHeaderAndFooter();
    scrollBack("store-info");
  }

  return (
    <div className="settingsContainer">
      <div className="settingsSection">
        <p className="settingsHeading"><b>Store info</b></p>
        <FormControlLabel
            className={"elementOptionInput"}
            control={<Switch id="store-info-show-logo" name='store-info-show-logo' defaultChecked={typeof values[ 'store-info-show-logo' ] === 'undefined' ? themeJsonDefaultValue['store-info-show-logo'] : values[ 'store-info-show-logo' ]} onChange={handleChange}/>}
            label='Show logo'
            style={{
              width: "90%"
            }}
        />
        <TextField
          className="textField"
          id="store-info-website"
          value={typeof values[ 'store-info-website' ] === 'undefined' ? themeJsonDefaultValue['store-info-website'] : values[ 'store-info-website' ]}
          label="Website"
          onChange={handleChange}
        />
        <TextField
          className="textField"
          id="store-info-logo"
          value={typeof values[ 'store-info-logo' ] === 'undefined' ? themeJsonDefaultValue['store-info-logo'] : values[ 'store-info-logo' ]}
          label="Logo URL"
          onChange={handleChange}
        />
        <TextField
          className="textField"
          id="store-info-details"
          value={typeof values[ 'store-info-details' ] === 'undefined' ? themeJsonDefaultValue['store-info-details'] : values[ 'store-info-details' ]}
          label="Store details"
          onChange={handleChange}
          multiline
          rows={4}
        />
        <div className="settingsDescription">
          <p>
            This controls the padding above and below the selected element. If global padding is applied this will be overridden.
          </p>
        </div>
        <Box sx={{ width: "90%", }}>
          <Typography id="spacer-size" gutterBottom>
            Padding Spacing
          </Typography>
          <Slider
              name="store-info-spacing"
              onChange={handleChange}
              min={0}
              max={50}
              value={typeof values[ 'store-info-spacing' ] === 'undefined' ? themeJsonDefaultValue['store-info-spacing'] : values[ 'store-info-spacing' ]}
              aria-labelledby="store-info-spacing"
              valueLabelDisplay="auto"
          />
        </Box>
        <ColorPicker
            className="colorPicker"
            hex={typeof values[ 'store-info-background-color' ] === 'undefined' ? themeJsonDefaultValue['store-info-background-color'] : values[ 'store-info-background-color' ]}
            label="Footer Background Color"
            id="store-info-background-color"
            setColor={handleChange}
        />
        <FormControlLabel
            style={{
              width: "90%"
            }}
            control={
              <Switch
                  id="store-info-local-font-settings-activated"
                  checked={typeof values[ 'store-info-local-font-settings-activated' ] === 'undefined' ? themeJsonDefaultValue['store-info-local-font-settings-activated'] : values[ 'store-info-local-font-settings-activated' ]}
                  onChange={(event) => {
                    handleLocalFontSettingsChange(event);
                    handleChange(event);
                  }}
              />
            }
            label="Apply local font settings"/>
        {isLocalFontSettingsActive &&
        <div>
          <ColorPicker
              hex={typeof values[ 'store-info-font-color' ] === 'undefined' ? themeJsonDefaultValue['store-info-font-color'] : values[ 'store-info-font-color' ]}
              label="Font Color"
              className="textField"
              id="store-info-font-color"
              setColor={handleChange}
          />
          <Box style={{margin: "20px 0"}}>
            <Typography id="store-info-font-size">
              Font Size
            </Typography>
            <Slider
                name="store-info-font-size"
                id="store-info-font-size"
                onChange={handleChange}
                min={5}
                max={35}
                defaultValue={typeof values[ 'store-info-font-size' ] === 'undefined' ? themeJsonDefaultValue['store-info-font-size'] : values[ 'store-info-font-size' ]}
                aria-labelledby="store-info-font-size"
                valueLabelDisplay="auto"
            />
          </Box>
        </div>
        }
      </div>
    </div>
  );
}

export default FooterSettings;
