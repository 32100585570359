import SmartButtonIcon from '@mui/icons-material/SmartButton';


const CompleteOrderButton = {
  type: "complete-order-button",
  name: "Complete Order Button",
  icon: <SmartButtonIcon/>,
  properties: [
    {
      name: "Order status button",
      description: "Allows customers to view their order details and status online.",
      content: [
        {
          id: "text-above-order-button",
          label: "TextElement above button",
          type: "textBox",
        },
        {
          id: "order-button-text",
          label: "Button text",
          type: "input",
        },
        {
          id: "text-below-order-button",
          label: "TextElement below button",
          type: "textBox",
        },
        {
          id: "button-color",
          label: "Button color",
          type: "colorPicker",
        },
        {
          id: "button-text-color",
          label: "Button text color",
          type: "colorPicker",
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ],
    },
  ],
  values: {
    "text-above-order-button": "You can follow the status of your order by clicking the button below:",
    "order-button-text": "View Order Status >",
    "text-below-order-button": "",
    "button-color": "#f64c3f",
    "button-text-color": "#ffffff",
    "text-align": "left",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10
  },
};

export default CompleteOrderButton;
