import TextSnippetIcon from "@mui/icons-material/TextSnippet";

const OrderNotes = {
  type: "order-notes",
  name: "Order Notes",
  icon: <TextSnippetIcon/>,
  properties: [
    {
      name: "Order Notes",
      content: [
        {
          id: "heading",
          label: "Heading",
          type: "input",
        },
        {
          id: "show-order-notes",
          label: "Show order notes",
          type: "checkbox",
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
  ],
  values: {
    "heading": "Order Notes",
    "show-order-notes": true,
    "text-align": "left",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10
  },
};

export default OrderNotes;
