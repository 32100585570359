import FilterFramesIcon from '@mui/icons-material/FilterFrames';
const OrderStatement = {
	type: "order-statement",
	name: "Order Statement",
	icon: <FilterFramesIcon/>,
	properties: [
		{
			name: "Order Statement Settings",
			content: [
				{
					id: "show-product-sku",
					label: "Show product SKU",
					type: "checkbox",
				},
				{
					id: "show-product-description",
					label: "Show product description",
					type: "checkbox"
				},
				{
					id: "show-product-title",
					label: "Show product title",
					type: "checkbox"
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		},
	],
	values: {
		"show-product-sku": true,
		"show-product-description": false,
		"show-product-title": false,
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default OrderStatement;
