import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { getUserSettings, saveGlobalElement } from "../../../../utilities";
import Slider from '@mui/material/Slider';
import {Switch} from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import ColorPicker from "../ColorPicker";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function LogoSettings ({
  reloadHeaderAndFooter,
  themeJson,
  userData,
  setUserData
})
{
  const [ values, setValues ]                     = useState(null);
  let themeJsonDefaultValue = typeof themeJson.templates["header"] !== "undefined" ? themeJson.templates["header"].values : themeJson.templates['default'].find(e => e.type === "header").values;

  // todo for sometime in future - put this into useEffect.
  if ( values === null ) {
    let valuesTmp = {};
    Object.values(themeJson.templates.default).forEach(el => {
      if ( el.type === 'header' ) {
        valuesTmp = el.values;
      }
    })

    let userSettings = userData;
    if ( typeof userSettings[ 'header' ] !== 'undefined' ) {
      Object.keys(userSettings[ 'header' ]).forEach(settingName => {
        valuesTmp[ settingName ] = userSettings[ 'header' ][ settingName ];
      })

      setValues(valuesTmp);
      return;
    } else {
      setValues(valuesTmp);
      return;
    }
  }

  const scrollBack = (elementId) => {
    let iframe = document.getElementById('previewIframe');
    let element = iframe.contentWindow.document.getElementById(elementId);
    let elementOffset  = element.offsetTop;

    iframe.addEventListener("load", function () {
      iframe.contentWindow.document.documentElement.scrollTo({
        top: elementOffset,
      });
    });
  }

  const handleChange = (event) => {
    // noinspection JSUnresolvedFunction
    let valuesTmp                = window.structuredClone(values);
    if ( event.value ){
      valuesTmp[ event.id ] = event.value;
    } else {
      if ( typeof event.target.id === 'undefined' || event.target.id === "" ) {
        valuesTmp[ event.target.name ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      } else {
        valuesTmp[ event.target.id ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      }
    }

    setValues(valuesTmp);

    saveGlobalElement('header', valuesTmp, userData).then(()=>{
      getUserSettings().then(res => setUserData(res));
    });
    reloadHeaderAndFooter();
    scrollBack("header-logo");
  }

  return (
    <div className="settingsContainer">
      <div className="settingsSection">
        <p className="settingsHeading"><b>Logo image settings</b></p>
        <TextField
          className="textField"
          id="header-logo"
          value={typeof values[ 'header-logo' ] === 'undefined' ? themeJsonDefaultValue[ 'header-logo' ] : values[ 'header-logo' ]}
          label="Logo url"
          onChange={handleChange}
        />
        <TextField
          className="textField"
          id="header-logo-link"
          value={typeof values[ 'header-logo-link' ] === 'undefined' ? themeJsonDefaultValue[ 'header-logo-link' ] : values[ 'header-logo-link' ]}
          label="Logo link ( optional )"
          onChange={handleChange}
        />
        <p>Logo image size</p>
        <Slider
          name="header-logo-size"
          className="logoSizeScale"
          onChange={handleChange}
          defaultValue={typeof values[ 'header-logo-size' ] === 'undefined' ? themeJsonDefaultValue[ 'header-logo-size' ] : values[ 'header-logo-size' ]}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <Stack
            style={{
              margin: "10px 0"
            }}
        >
          <ToggleButtonGroup
              value={typeof values[ 'header-logo-position' ] === 'undefined' ? themeJsonDefaultValue[ 'header-logo-position' ] : values[ 'header-logo-position' ]}
              exclusive
              onChange={handleChange}
              aria-label="header-logo-position">
            <ToggleButton name="header-logo-position" value="left" aria-label="left aligned">
              <FormatAlignLeftIcon pointerEvents="none"/>
            </ToggleButton>,
            <ToggleButton name="header-logo-position" value="center" aria-label="centered">
              <FormatAlignCenterIcon pointerEvents="none"/>
            </ToggleButton>,
            <ToggleButton name="header-logo-position" value="right" aria-label="right aligned">
              <FormatAlignRightIcon pointerEvents="none"/>
            </ToggleButton>,
          </ToggleButtonGroup>
        </Stack>
        <FormControlLabel
          className="logoFullWidth"
          style={{
            margin: "10px 0 20px 0",
            width: "90%"
          }}
          control={
            <Switch
              id="header-logo-full-width"
              defaultChecked={typeof values[ 'header-logo-full-width' ] === 'undefined' ? themeJsonDefaultValue[ 'header-logo-full-width' ] : values[ 'header-logo-full-width' ]}
              onChange={handleChange}
            />
          }
          label="Logo full width"
        />
        <ColorPicker
            hex={typeof values[ 'header-logo-background-color' ] === 'undefined' ? themeJsonDefaultValue[ 'header-logo-background-color' ] : values[ 'header-logo-background-color' ]}
            label="Header Logo Background Color"
            id="header-logo-background-color"
            setColor={handleChange}
            style={{
              width: "90%"
            }}
        />
        <div className="settingsDescription">
          <p>
            This controls the padding above and below the selected element. If global padding is applied this will be overridden.
          </p>
        </div>
        <Box sx={{ width: "90%", }}>
          <Typography id="spacer-size" gutterBottom>
            Padding Spacing
          </Typography>
          <Slider
              name="header-logo-spacing"
              onChange={handleChange}
              min={0}
              max={50}
              value={typeof values[ 'header-logo-spacing' ] === 'undefined' ? themeJsonDefaultValue[ 'header-logo-spacing' ] : values[ 'header-logo-spacing' ]}
              aria-labelledby="header-logo-spacing"
              valueLabelDisplay="auto"
          />
        </Box>
      </div>
    </div>
  );
}

export default LogoSettings;
