import PercentIcon from '@mui/icons-material/Percent';

const Discount = {
	type: "discount",
	name: "Discount",
	icon: <PercentIcon/>,
	properties: [
		{
			name: "Discount",
			content: [
				{
					id: "heading",
					label: "Heading",
					type: "input",
				},
				{
					id: "description-text",
					label: "Description text",
					type: "textBox",
				},
				{
					id: "discount-code",
					label: "Discount code",
					type: "input",
				},
				{
					id: "button-text",
					label: "Button text",
					type: "input",
				},
				{
					id: "button-link",
					label: "Button link",
					type: "input",
					description: "(leave blank for no button)",
				},
				{
					id: "background-color",
					label: "Background color",
					type: "colorPicker",
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "button-color",
					label: "Button color",
					type: "colorPicker",
				},
				{
					id: "button-text-color",
					label: "Button text color",
					type: "colorPicker",
				},
				{
					id: "terms-and-conditions",
					label: "Terms and conditions link",
					type: "input",
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		},
	],
	values: {
		"heading": "10% OFF DISCOUNT",
		"description-text": "As thanks for shopping with us, we're giving you a discount coupon to use on your next purchase.",
		"discount-code": "TAKE10OFF",
		"button-text": "Shop Now >",
		"button-link": "https://linktoyourstore.com",
		"background-color": "#fdf4da",
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"button-color": "#EC562C",
		"button-text-color": "#FFFFFF",
		"terms-and-conditions": "Add terms & conditions here, if needed",
		"spacing-size": 10
	},
};

export default Discount;
