import BorderColorIcon from '@mui/icons-material/BorderColor';

const CustomerStatement = {
  type: "customer-statement",
  name: "Customer Statement",
  icon: <BorderColorIcon/>,
  properties: [
    {
      name: "Heading",
      content: [
        {
          id: "heading",
          label: "Heading",
          type: "input",
        },
        {
          id: "note",
          label: "Note",
          type: "input",
        }
      ]
    },
    {
      name: "General",
      content: [
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
    {
      name: "Customer Info",
      content: [
        {
          id: "show-terms",
          label: "Show Terms",
          type: "checkbox"
        },
        {
          id: "terms",
          label: "Terms",
          type: "input"
        }
      ]
    },
    {
      name: "Company Info",
      content: [
        {
          id: "show-company-name",
          label: "Show Company Name",
          type: "checkbox",
        },
        {
          id: "show-company-address",
          label: "Show Company Address",
          type: "checkbox"
        },
        {
          id: "show-company-phone",
          label: "Show Company Telephone",
          type: "checkbox"
        },
        {
          id: "show-company-email",
          label: "Show Company Email",
          type: "checkbox"
        },
        {
          id: "show-company-website",
          label: "Show Company Website",
          type: "checkbox"
        }
      ]
    }
  ],
  values: {
    "heading": "",
    "note": "",
    "text-align": "left",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10,
    "show-terms": true,
    "terms": "Payment Terms e.g. 30 days",
    "show-company-name": true,
    "show-company-address": false,
    "show-company-phone": false,
    "show-company-email": false,
    "show-company-website": false,
  },
};

export default CustomerStatement;
