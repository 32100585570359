import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const RmaProducts = {
	type: "rma-products",
	name: "RMA Products List",
	icon: <LocalOfferIcon/>,
	properties: [
		{
			name: "General Product Settings",
			content: [
				{
					id: "background-color",
					label: "Background color",
					type: "colorPicker"
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				},
				{
					id: "show-product-sku",
					label: "Show product SKU",
					type: "checkbox",
				},
				{
					id: "show-product-vendor",
					label: "Show product vendor",
					type: "checkbox"
				},
				{
					id: "show-product-description",
					label: "Show product description",
					type: "checkbox"
				},
				{
					id: "show-product-info",
					label: "Show info added by product options apps",
					type: "checkbox"
				},
				{
					id: "show-product-title",
					label: "Product title links to product page on website",
					type: "checkbox"
				},
			]
		},
	],
	values: {
		"background-color": "#f5f5f5",
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10,
		"show-product-sku": true,
		"show-product-vendor": false,
		"show-product-description": false,
		"show-product-info": false,
		"show-product-title": false,
	},
};

export default RmaProducts;
