import React from "react";
import { Button, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";

function PresetPopup({
  name,
  handlePresetPopup,
}) {

  const handleThemeChange = () => {
    //TODO: Finish this functionality!
    alert(name);
  }

  return(
    <Fade
      in={name}
      timeout={600}
      style={{ transitionDelay:'100ms'}}
    >
      <div className="popupWrapper" onClick={e=>{
        if (e.target.classList.contains('popupWrapper')){
          handlePresetPopup(false);
        }
        e.stopPropagation();
        return false;
      }}>
        <Paper className="popup">
          <div className="popupElementsWrapper">
            <div className="popupHeading">
              <h2 style={{marginTop: 0, fontWeight: 600,}}>Apply Preset - {name}</h2>
              <CloseIcon
                onClick={() => handlePresetPopup(false)}
                className="closeButton"
              />
            </div>
            <div className="popupBody">
              <div className="descriptionSection">
                <p>Are you sure? This will overwrite all logo sizing, typography & color settings in your design. This cannot be undone.</p>
              </div>
            </div>
            <div className="popupFooter">
              <Button
                style={{
                  color: '#212121',
                  border: '1px solid #212121',
                  borderRadius: '0',
                  margin: "10px 5px",
                }}
                onClick={() => handlePresetPopup(false)}
                variant="outlined"
                size="small"
              >
                Cancel
              </Button>
              <Button
                style={{
                  border: '1px solid #212121',
                  borderRadius: '0',
                  margin: "10px 5px",
                  backgroundColor: "#212121",
                }}
                variant="contained"
                size="small"
                onClick={() => handleThemeChange()}
              >
                Apply Preset
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </Fade>
  );
}

export default PresetPopup;
