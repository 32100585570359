import React from "react";
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import LinkIcon from '@mui/icons-material/Link';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { Button } from "@mui/material";
import LogoSettings from "./theme-settings/LogoSettings";
import GlobalStyles from "./theme-settings/GlobalStyles";
import SiteLinksSettings from "./theme-settings/SiteLinksSettings";
import SocialSettings from "./theme-settings/SocialSettings";
import FooterSettings from "./theme-settings/FooterSettings";

function ThemeSettings({
  setThemeSettings,
  setIsThemeSettingsOpen,
  setIsThemeStyleOpen,
  reloadHeaderAndFooter,
  themeJson,
  userData,
  setUserData,
  setIsElementEditorOpen,
}){

  return(
    <div id="themeSettings">
      <ul className="settingElements">
        <li
          onClick={() => {
            setThemeSettings({
                component: <LogoSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
                label: "Logo"
            });
            setIsThemeSettingsOpen(true);
          }}
        >
            <div className="logo">
              <AutoAwesomeMosaicIcon fontSize={"medium"}/>
              Logo
            </div>
          <ArrowForwardIosIcon/>
        </li>
        <li
          onClick={() => {
            setThemeSettings({
                component: <GlobalStyles reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
                label: "Global styles"
            });
            setIsThemeSettingsOpen(true);
          }}
        >
          <div className="logo">
            <FormatPaintIcon fontSize={"medium"}/>
              Global Styles
          </div>
          <ArrowForwardIosIcon/>
        </li>
        <li
          onClick={() => {
            setIsElementEditorOpen(false);
            setThemeSettings({
              component: <SiteLinksSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
              label: "Site links"
            });
            setIsThemeSettingsOpen(true);
          }}
        >
          <div className="logo">
            <LinkIcon fontSize={"medium"}/>
            Site links
          </div>
          <ArrowForwardIosIcon/>
        </li>
        <li
          onClick={() => {
            setThemeSettings({
                component: <SocialSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
                label: "Social"
            });
            setIsThemeSettingsOpen(true);
          }}
        >
          <div className="logo">
            <ThumbUpAltIcon fontSize={"medium"}/>
            Social
          </div>
          <ArrowForwardIosIcon/>
        </li>
        <li
          onClick={() => {
            setThemeSettings({
                component: <FooterSettings reloadHeaderAndFooter={reloadHeaderAndFooter} themeJson={themeJson} userData={userData} setUserData={setUserData}/>,
                label: "Store info / footer"
            });
            setIsThemeSettingsOpen(true);
          }}
        >
          <div className="logo">
            <StoreMallDirectoryIcon fontSize={"medium"}/>
            Store info / footer
          </div>
          <ArrowForwardIosIcon/>
        </li>
      </ul>
      <div className="themeSettingsButtons">
        <Button
          variant="outlined"
          size="small"
          onClick={() => setIsThemeStyleOpen(true)}
        >
          Change theme style
        </Button>
      </div>
    </div>

  );
}

export default ThemeSettings;
