import TitleIcon from "@mui/icons-material/Title";

const Heading = {
  type: "heading",
  name: "Heading",
  icon: <TitleIcon/>,
  properties: [
    {
      name: "Heading",
      content: [
        {
          id: "text",
          label: "Heading",
          type: "input",
        },
        {
          id: "heading-size",
          label: "Heading Size",
          type: "select",
          options: {
            "h1": "H1",
            "h2": "H2",
            "h3": "H3",
            "h4": "H4",
          },
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
  ],
  values: {
    "text": "Order Confirmation",
    "heading-size": "h1",
    "text-align": "center",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "spacing-size": 10
  },
};

export default Heading;
