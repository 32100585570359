import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const ProductsWithPricing = {
  type: "product-with-pricing",
  name: "Products with pricing",
  icon: <LocalOfferIcon/>,
  properties: [
    {
      name: "Products with pricing",
      content: [
        {
          id: "heading",
          label: "Heading",
          type: "input",
        },
        {
          id: "background-color",
          label: "Background color",
          type: "colorPicker"
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        }
      ]
    },
    {
      name: "General Product Settings",
      content: [
        {
          id: "show-product-sku",
          label: "Show product SKU",
          type: "checkbox",
        },
        {
          id: "show-product-vendor",
          label: "Show product vendor",
          type: "checkbox"
        },
        {
          id: "show-product-description",
          label: "Show product description",
          type: "checkbox"
        },
        {
          id: "show-product-title",
          label: "Product title links to product page on website",
          type: "checkbox"
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
    {
      name: "Product Images",
      content: [
        {
          id: "product-image-size",
          label: "Product image size",
          type: "select",
          options: {
            70: "Thumbnail",
            80: "Small",
            100: "Medium",
          }
        },
        {
          id: "product-image-square",
          label: "Make product images square",
          type: "checkbox"
        },
      ],
    },
    {
      name: "Pricing settings",
      content: [
        {
          id: "show-total-excl-taxes",
          label: "Show \"Total excl. taxes\" line",
          type: "checkbox",
        },
        {
          id: "show-individual-tax",
          label: "Show individual tax lines",
          type: "checkbox",
        },
        {
          id: "subtotal-text",
          label: "Subtotal text",
          type: "input",
        },
        {
          id: "duties-text",
          label: "Import duties",
          type: "input",
          description: "Only shows if import duties are charged on the order",
        },
        {
          id: "discount-text",
          label: "Discount",
          type: "input",
          description: "Only shows if a discount was used on the order",
        },
        {
          id: "total-tax-text",
          label: "Sales tax",
          type: "input",
          description: "Only shows if 'Show individual tax lines' is disabled above, and taxes have been charged",
        },
        {
          id: "total-excl-taxes-text",
          label: "Total excl. text",
          type: "input",
          description: "Only shows if the setting \"Show Total excl. taxes line\" is enabled above",
        },
        {
          id: "total-text",
          label: "Total text",
          type: "input",
        },
        {
          id: "refund-text",
          label: "Refund",
          type: "input",
          description: "Only shows if a refund has been issued",
        },
      ]
    },
  ],
  values: {
    "heading": "Updated order",
    "background-color": "#f5f5f5",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "show-product-sku": false,
    "show-product-vendor": false,
    "show-product-description": false,
    "show-product-info": true,
    "show-product-title": true,
    "spacing-size": 10,
    "product-image-size": 140,
    "product-image-square": true,
    "show-total-excl-taxes": false,
    "show-individual-tax": false,
    "subtotal-text": "Subtotal",
    "duties-text": "Import duties",
    "discount-text": "Discount",
    "total-tax-text": "Sales tax",
    "tips-text": "Tip",
    "total-excl-taxes-text": "Total excl. tax",
    "total-text": "Total",
    "refund-text": "Refund",
  },
};

export default ProductsWithPricing;
