import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const DeliveryList = {
    type: "delivery-list",
    name: "Delivery List",
    icon: <PlaylistAddCheckIcon/>,
    properties: [
        {
            name: "Delivery List",
            content: [
                {
                    id: "delivery-heading",
                    label: "Heading",
                    type: "input",
                },
                {
                    id: "show-delivery-product-sku",
                    label: "Show product SKU",
                    type: "checkbox",
                },
                {
                    id: "background-color",
                    label: "Background color",
                    type: "colorPicker"
                },
                {
                    id: "text-align",
                    label: "Text Align",
                    type: "toggleAlignment",
                },
                {
                    id: "local-font-settings-activated",
                    label: "Apply local font settings",
                    type: "checkbox"
                },
                {
                    id: "font-color",
                    label: "Font Color",
                    type: "localFontColor"
                },
                {
                    id: "font-size",
                    label: "Font Size",
                    type: "conditionalToggle",
                    options: {
                        min: 5,
                        max: 35
                    }
                },
                {
                    id: "spacing-size",
                    description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
                    label: "Padding Size",
                    type: "toggle",
                    options: {
                        "min": 0,
                        "max": 50,
                    }
                }
            ]
        },
        {
            name: "Product Images",
            content: [
                {
                    id: "delivery-list-image-size",
                    label: "Product image size",
                    type: "select",
                    options: {
                        100: "Thumbnail",
                        120: "Small",
                        140: "Medium",
                    }
                },
                {
                    id: "show-delivery-product-image",
                    label: "Show Product Image",
                    type: "checkbox",
                },
            ],
        }
    ],
    values: {
        "delivery-heading": "Items That Have Been Delivered",
        "show-delivery-product-sku": false,
        "background-color": "#f5f5f5",
        "text-align": "left",
        "local-font-settings-activated": false,
        "font-color": "#6b6b6b",
        "font-size": 15,
        "spacing-size": 10,
        "show-delivery-product-image": true,
        "delivery-list-image-size": 140,
        "delivery-list-note": ""
    },
};

export default DeliveryList;
