import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";

function MakingScreenshotsPopup({
  setIsMakingScreenshots,
  setCurrentTemplate,
  allTemplates,
}) {

  return (
      <div className="popupWrapper" onClick={e=>{
        if (e.target.classList.contains('popupWrapper')){
          setIsMakingScreenshots(false);
        }
        e.stopPropagation();
        return false;
      }}>
        <Paper
          className="popup"
        >
          <div className="popupElementsWrapper">
            <div className="popupHeading">
              <h2 style={{marginTop: 0}}>Export screenshots</h2>
              <CloseIcon
                onClick={() => setIsMakingScreenshots(false)}
                className="closeButton"
              />
            </div>
            <div className="popupBody">
              <div className="screenshotsDescription">
                <h4>Loading screenshots...</h4>
                <p> 1 \ {allTemplates.length}</p>
              </div>
            </div>
          </div>
        </Paper>
      </div>
  );
}

export default MakingScreenshotsPopup;