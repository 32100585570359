import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function ThemeStyleHeader({
  setIsThemeStyleOpen,
  setThemeSettings,
}) {

  return(
    <div className="headerBackButton">
      <div
        className="heading"
        onClick={
          () => {
            setIsThemeStyleOpen(false)
            setThemeSettings(true)
          }
        }
      >
          <ArrowBackIosIcon className="arrowBackIcon"/>
        <b>Change theme style</b>
      </div>
    </div>
  );
}

export default ThemeStyleHeader;