import PersonIcon from '@mui/icons-material/Person';

const UserInformation = {
  type: "user-information",
  name: "User Information",
  icon: <PersonIcon/>,
  properties: [
    {
      name: "User Information",
      content: [
        {
          id: "information-text",
          label: "Information text",
          type: "textBox",
        },
        {
          id: "email",
          label: "Email",
          type: "input"
        },
        {
          id: "show-username",
          label: "Show Username",
          type: "checkbox"
        },
        {
          id: "username",
          label: "Username",
          type: "input"
        },
        {
          id: "show-password",
          label: "Show Password Information",
          type: "checkbox"
        },
        {
          id: "show-group",
          label: "Show Group",
          type: "checkbox",
        },
        {
          id: "group",
          label: "Group",
          type: "input"
        },
        {
          id: "background-color",
          label: "Background color",
          type: "colorPicker"
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ]
    },
  ],
  values: {
    "information-text": "A new user registered on [$company_name$].\n\n\nUsername: Example Value\n\n\nEmail: Example Value\n\n\nGroup: Example Value",
    "show-username": true,
    "show-password": false,
    "show-group": true,
    "background-color": "#f5f5f5",
    "text-align": "left",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10
  },
};

export default UserInformation;
