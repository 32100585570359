import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const VoucherDetails = {
	type: "voucher-details",
	name: "Voucher Details",
	icon: <LocalActivityIcon/>,
	properties: [
		{
			name: "Voucher Details",
			content: [
				{
					id: "voucher-heading",
					label: "Heading",
					type: "input",
				},
				{
					id: "voucher-instructions",
					label: "Voucher Instructions",
					type: "textBox"
				},
				{
					id: "background-color",
					label: "Background color",
					type: "colorPicker",
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		}
	],
	values: {
		"voucher-heading": "Voucher Details:",
		"voucher-instructions": "",
		"background-color": "#fdf4da",
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10
	},
};

export default VoucherDetails;
