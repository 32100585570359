import FactCheckIcon from '@mui/icons-material/FactCheck';

const DispatchList = {
	type: "dispatch-list",
	name: "Dispatch List",
	icon: <FactCheckIcon/>,
	properties: [
		{
			name: "Dispatch List",
			content: [
				{
					id: "heading",
					label: "Heading",
					type: "input",
				},
				{
					id: "dispatch-list-note",
					label: "Dispatch List Note",
					type: "input",
				},
				{
					id: "show-product-sku",
					label: "Show product SKU",
					type: "checkbox",
				},
				{
					id: "background-color",
					label: "Background color",
					type: "colorPicker"
				},
				{
					id: "text-align",
					label: "Text Align",
					type: "toggleAlignment",
				},
				{
					id: "local-font-settings-activated",
					label: "Apply local font settings",
					type: "checkbox"
				},
				{
					id: "font-color",
					label: "Font Color",
					type: "localFontColor"
				},
				{
					id: "font-size",
					label: "Font Size",
					type: "conditionalToggle",
					options: {
						min: 5,
						max: 35
					}
				},
				{
					id: "spacing-size",
					description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
					label: "Padding Size",
					type: "toggle",
					options: {
						"min": 0,
						"max": 50,
					}
				}
			]
		},
		{
			name: "Product Images",
			content: [
				{
					id: "dispatch-list-image-size",
					label: "Product image size",
					type: "select",
					options: {
						100: "Thumbnail",
						120: "Small",
						140: "Medium",
					}
				},
				{
					id: "dispatch-list-image-square",
					label: "Make product images square",
					type: "checkbox"
				},
				{
					id: "show-product-image",
					label: "Show Product Image",
					type: "checkbox",
				},
			],
		}
	],
	values: {
		"heading": "Items That Have Been Dispatched",
		"dispatch-list-note": "some items on your order may arrive separately if they are sent using different shipping methods.",
		"show-product-sku": false,
		"background-color": "#f5f5f5",
		"text-align": "left",
		"local-font-settings-activated": false,
		"font-color": "#6b6b6b",
		"font-size": 15,
		"spacing-size": 10,
		"show-product-image": true,
		"dispatch-list-image-size": 140,
		"dispatch-list-image-square": false
	},
};

export default DispatchList;
