import IntroductionText from "../containers/Editor/elements/IntroductionText";
import RmaProducts from "../containers/Editor/elements/RmaProducts";
import VoucherDetails from "../containers/Editor/elements/VoucherDetails";
import ClosingText from "../containers/Editor/elements/ClosingText";
import Divider from "../containers/Editor/elements/Divider";
import OrderStatusButton from "../containers/Editor/elements/OrderStatusButton";
import Discount from "../containers/Editor/elements/Discount";
import OrderNumberAndDate from "../containers/Editor/elements/OrderNumberAndDate";
import AddressDetails from "../containers/Editor/elements/AddressDetails";
import PaymentInformation from "../containers/Editor/elements/PaymentInformation";
import OrderNotes from "../containers/Editor/elements/OrderNotes";
import ProductsWithPricing from "../containers/Editor/elements/ProductsWithPricing";
import SiteLinks from "../containers/Editor/elements/SiteLinks";
import Social from "../containers/Editor/elements/Social";
import StoreInfo from "../containers/Editor/elements/StoreInfo";
import ButtonElement from "../containers/Editor/elements/ButtonElement";
import TextElement from "../containers/Editor/elements/TextElement";
import CompleteOrderButton from "../containers/Editor/elements/CompleteOrderButton";
import ProductList from "../containers/Editor/elements/ProductList";
import OnlinePaymentReminder from "../containers/Editor/elements/OnlinePaymentReminder";
import OrderStatement from "../containers/Editor/elements/OrderStatement";
import PaymentMethods from "../containers/Editor/elements/PaymentMethods";
import Heading from "../containers/Editor/elements/Heading";
import DispatchList from "../containers/Editor/elements/DispatchList";
import DeliveryList from "../containers/Editor/elements/DeliveryList";
import ReviewRequestList from "../containers/Editor/elements/ReviewRequestList";
import CheckProductButton from "../containers/Editor/elements/CheckProductButton";
import CustomerStatement from "../containers/Editor/elements/CustomerStatement";
import UserInformation from "../containers/Editor/elements/UserInformation";
import ReturnToHomepage from "../containers/Editor/elements/ReturnToHomepage";
import Password from "../containers/Editor/elements/Password";

const elementsPerTemplate = {
  "rma-message": [
    IntroductionText,
    RmaProducts,
    TextElement,
    ClosingText,
  ],
  "reward-program-notifications": [
    IntroductionText,
    VoucherDetails,
    ClosingText,
  ],
  "order-confirmation": [
    Divider,
    IntroductionText,
    OrderStatusButton,
    Discount,
    OrderNumberAndDate,
    Divider,
    AddressDetails,
    PaymentInformation,
    OrderNotes,
    ProductsWithPricing,
    SiteLinks,
    Social,
    StoreInfo,
    ClosingText,
  ],
  "order-quote": [
    Divider,
    IntroductionText,
    ButtonElement,
    TextElement,
    ClosingText,
  ],
  "abandoned-cart": [
    Divider,
    IntroductionText,
    CompleteOrderButton,
    ProductList,
    ClosingText
  ],
  "first-payment-reminder": [
    Divider,
    IntroductionText,
    OnlinePaymentReminder,
    OrderStatement,
    PaymentMethods,
    ClosingText
  ],
  "second-payment-reminder": [
    IntroductionText,
    OnlinePaymentReminder,
    OrderStatement,
    PaymentMethods,
    ClosingText,
  ],
  "final-payment-reminder": [
    Divider,
    IntroductionText,
    TextElement,
    Heading,
    OnlinePaymentReminder,
    OrderStatement,
    PaymentMethods,
    ClosingText,
  ],
  "send-to-pack-notification": [
    Divider,
    IntroductionText,
    ButtonElement,
    DispatchList,
    ClosingText,
  ],
  "proof-of-delivery": [
    Divider,
    IntroductionText,
    DeliveryList,
    ClosingText
  ],
  "order-tracking": [
    Divider,
    IntroductionText,
    DispatchList,
    ClosingText
  ],
  "order-follow-up": [
    Divider,
    IntroductionText,
    TextElement,
    ReviewRequestList,
    ClosingText
  ],
  "failed-checkout": [
    Divider,
    IntroductionText,
    TextElement,
    ClosingText
  ],
  "back-in-stock-notification": [
    Divider,
    IntroductionText,
    CheckProductButton,
    ClosingText
  ],
  "gift-voucher": [
    Divider,
    IntroductionText,
    VoucherDetails,
    ClosingText
  ],
  "customer-statement": [
    IntroductionText,
    CustomerStatement,
    ClosingText
  ],
  "wholesale-customer-registration-notification-(to-company)": [
    Divider,
    UserInformation,
    ClosingText
  ],
  "non-registered-customer-account-activation": [
    Divider,
    TextElement,
    ButtonElement,
    ClosingText
  ],
  "customer-registration-welcome-(to-customer)": [
    Divider,
    UserInformation,
    ReturnToHomepage,
    ClosingText
  ],
  "customer-registration-notification-(to-company)": [
    Divider,
    UserInformation,
    ClosingText
  ],
  "customer-referral-commission-notification": [
    Divider,
    IntroductionText,
    TextElement,
    ClosingText
  ],
  "customer-recover-username": [
    Divider,
    IntroductionText,
    UserInformation,
    TextElement,
    ClosingText
  ],
  "customer-recover-password": [
    Divider,
    IntroductionText,
    Password,
    TextElement,
    ClosingText
  ],
  "customer-change-password-notification": [
    Divider,
    IntroductionText,
    ClosingText
  ]
};

export default elementsPerTemplate;
