import PasswordIcon from '@mui/icons-material/Password';

const Password = {
  type: "password",
  name: "Password",
  icon: <PasswordIcon/>,
  properties: [
    {
      name: "Password",
      content: [
        {
          id: "description-text",
          label: "Description Text",
          type: "textBox",
        },
        {
          id: "background-color",
          label: "Background color",
          type: "colorPicker"
        },
        {
          id: "text-align",
          label: "Text Align",
          type: "toggleAlignment",
        },
        {
          id: "local-font-settings-activated",
          label: "Apply local font settings",
          type: "checkbox"
        },
        {
          id: "font-color",
          label: "Font Color",
          type: "localFontColor"
        },
        {
          id: "font-size",
          label: "Font Size",
          type: "conditionalToggle",
          options: {
            min: 5,
            max: 35
          }
        },
        {
          id: "spacing-size",
          description: "This controls the padding above and below the selected element. If global padding is applied this will be overridden.",
          label: "Padding Size",
          type: "toggle",
          options: {
            "min": 0,
            "max": 50,
          }
        }
      ],
    },
  ],
  values: {
    "description-text": "For security purposes, we have generated you a new password.\n\n\nBelow is your new password:",
    "background-color": "#f5f5f5",
    "text-align": "left",
    "local-font-settings-activated": false,
    "font-color": "#6b6b6b",
    "font-size": 15,
    "spacing-size": 10
  },
};

export default Password;
