import React from "react";
import Slide from "@mui/material/Slide";

function ElementPicker({
  templateElements,
  commonElements,
  pushElement,
  setIsElementPickerOpen,
  isElementPickerOpen,
}) {


  return (
    <Slide direction="right" in={isElementPickerOpen}>
      <div id="elementPicker">
        <ul className="templateElements elements">
          {templateElements.forEach(element => {
            if ( element.type !== 'site-links' && element.type !== 'store-info' && element.type !== 'social' ){
              return (
                <li
                  key={element.id}
                  onClick={() =>
                  {
                    setIsElementPickerOpen(false)
                    pushElement(element.type)
                  }}>
                  {element.name}
                </li>
              )
          }
          })}
        </ul>
        <h2 className="heading">Common Elements</h2>
        <ul className="commonElements elements">
          {commonElements.map(element => (
            <li key={element.id} onClick={() => {
              setIsElementPickerOpen(false)
              pushElement(element.type)
            }}>
              <div className="elementIcon">
                {element.icon}
              </div>
              <p>
                {element.name}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </Slide>

  );
}

export default ElementPicker;
