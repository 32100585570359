import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

const Social = {
	type: "social",
	name: "Social",
	icon: <ThumbUpAltIcon/>,
	properties: [
		{
			name: "Social Media Links",
			description: "Add links to the social media services that you use.",
			content: [
				{
					id: "facebook",
					label: "Facebook",
					type: "input",
				},
				{
					id: "instagram",
					label: "Instagram",
					type: "input",
				},
				{
					id: "twitter",
					label: "Twitter",
					type: "input",
				},
				{
					id: "you-tube",
					label: "You Tube",
					type: "input",
				},
			]
		}
	],
	values: {},
};

export default Social;
