const availableTemplates = [
  // =======================================
  'RMA Email',
  {
    'id': 'rma-message',
    'value': 'RMA Message Email'
  },
  'Reward Program Notification',
  {
    'id': 'reward-program-notifications',
    'value': 'Reward Program Notifications'
  },
  // =======================================
  'Payment Reminder',
  {
    'id': 'first-payment-reminder',
    'value': 'First Payment Reminder Email'
  },
  {
    'id': 'second-payment-reminder',
    'value': 'Second Payment Reminder Email'
  },
  {
    'id': 'final-payment-reminder',
    'value': 'Final Payment Reminder Email'
  },
  // =======================================
  'Order Emails',
  {
    'id': 'send-to-pack-notification',
    'value': 'Send to Pack Notification Email',
  },
  {
    'id': 'proof-of-delivery',
    'value': 'Proof Of Delivery',
  },
  {
    'id': 'order-tracking',
    'value': 'Order Tracking Email',
  },
  {
    'id': 'order-quote',
    'value': 'Order Quote Email',
  },
  {
    'id': 'order-follow-up',
    'value': 'Order Follow Up Email',
  },
  {
    'id': 'order-confirmation',
    'value': 'Order Receipt Email',
  },
  // =======================================
  'Gift Voucher',
  {
    'id': 'gift-voucher',
    'value': 'Gift Voucher Emails',
  },
  // =======================================
  'Customer Statement',
  {
    'id': 'customer-statement',
    'value': 'Customer Statement Template Email',
  },
  // =======================================
  'Customer Registration Emails',
  {
    'id': 'wholesale-customer-registration-notification-(to-company)',
    'value': 'Wholesale Customer Registration Notification Email (To Company)',
  },
  {
    'id': 'non-registered-customer-account-activation',
    'value': 'Non-Registered Customer Account Activation Email',
  },
  {
    'id': 'customer-registration-welcome-(to-customer)',
    'value': 'Customer Registration Welcome (To Customer)',
  },
  {
    'id': 'customer-registration-notification-(to-company)',
    'value': 'Customer Registration Notification Email (To Company)',
  },
  // =======================================
  'Customer Emails',
  {
    'id': 'customer-recover-password',
    'value': 'Customer Recover Password Email',
  },
  // =======================================
  'Back in Stock Email',
  {
    'id': 'back-in-stock-notification',
    'value': 'Back In Stock Notification',
  },
  // =======================================
  'Abandoned Cart',
  {
    'id': 'abandoned-cart',
    'value': 'Abandoned Cart Customer Email or Abandoned Cart Email Template',
  }
];

export default availableTemplates;
