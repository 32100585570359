import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveElementPopup from "./RemoveElementPopup";
import Slide from '@mui/material/Slide';
import ColorPicker from "./ColorPicker";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Collapse, Switch} from "@material-ui/core";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function ElementOptions({
  setIsElementEditorOpen,
  currentElement,
  currentElements,
  setCurrentElements,
  isElementEditorOpen,
  setCurrentSettings,
  themeJson,
  currentTemplate
}) {
  const currentElementSettings                                    = currentElement.properties;
  const [ isRemoveElementPopupOpen, setIsRemoveElementPopupOpen ] = useState(false);
  const [ values, setValues ]                                     = useState({});
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleRemoveElementPopup = () => {
    setIsRemoveElementPopupOpen(! isRemoveElementPopupOpen);
  };

  const RenderSetting = (setting) => {
    // let value          = typeof values[ setting.id ] !== "undefined" ? values[ setting.id ] : '';
    let themeJsonDefaultValue = typeof themeJson.templates[currentTemplate].find(e => e.type === currentElement.type) !== "undefined" ? themeJson.templates[currentTemplate].find(e => e.type === currentElement.type).values[setting.id] : themeJson.templates['default'].find(e => e.type === currentElement.type).values[setting.id];
    const defaultValue = typeof currentElement.values[ setting.id ] !== "undefined" ? currentElement.values[ setting.id ] : themeJsonDefaultValue;
    const handleChange = (event) => {
      // noinspection JSUnresolvedFunction
      let valuesTmp           = window.structuredClone(values);
      if ( event.value ){
        valuesTmp[ setting.id ] = event.value;
        setValues(valuesTmp);
        setCurrentSettings(currentElement.id, setting.id, event.value);
      } else {
        valuesTmp[ setting.id ] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setValues(valuesTmp);
        setCurrentSettings(currentElement.id, setting.id, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
      }
    };

    const handleTextChange = async event => {
      await delay(2000);
      // noinspection JSUnresolvedFunction
      let valuesTmp           = window.structuredClone(values);
      if ( event.target.value || event.target.value === "" ){
        valuesTmp[ setting.id ] = event.target.value;
        setValues(valuesTmp);
        setCurrentSettings(currentElement.id, setting.id, event.target.value);
      }
    }

    switch ( setting.type ) {
      case "input":
        return (
          <TextField
            className="elementOptionInput"
            label={setting.label}
            defaultValue={defaultValue}
            onChange={handleTextChange}
          />
        );
      case "select":
        return (
          <FormControl className="elementOptionInput">
            <InputLabel id="demo-simple-select-helper-label">{setting.label}</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              label={setting.label}
              value={currentElement.values[ setting.id ] !== "undefined" ? currentElement.values[ setting.id ] : ''}
              onChange={handleChange}
            >
              {Object.entries(setting.options)
                .map(([ key, value ], index) => (
                  <MenuItem value={key} key={index}>{value}</MenuItem>
                ))};
            </Select>
          </FormControl>
        );
      case "textBox":
        return (<TextField
          className="elementOptionInput"
          id="outlined-multiline-static"
          label={setting.label}
          multiline
          rows={4}
          defaultValue={defaultValue}
          onChange={handleTextChange}
        />);
      case "checkbox":
        return (
          <FormControlLabel
            className="elementOptionInput"
            control={<Switch name={setting.id} checked={defaultValue} onChange={handleChange}/>}
            label={setting.label}
          />
        );
      case "localFontColor":
        return (
            <Collapse in={currentElement.values["local-font-settings-activated"]}>
              <ColorPicker
                  hex={defaultValue}
                  label={setting.label}
                  className="elementOptionInput"
                  setColor={handleChange}
              />
            </Collapse>
        );
      case "conditionalToggle":
          let mainElement = "";
          if (currentElement.values["local-font-settings-activated"]){
              mainElement = "local-font-settings-activated";
          } else {
              mainElement = setting.conditionField;
          }
        return (
            <Collapse in={currentElement.values[mainElement]} className="local-font-toggle">
              <Box>
                <Typography id="spacer-size" gutterBottom>
                  {setting.label}
                </Typography>
                <Slider
                    name="spacer-size"
                    className="elementOptionInput"
                    onChange={handleChange}
                    min={setting.options.min}
                    max={setting.options.max}
                    value={defaultValue}
                    aria-labelledby="spacer-size"
                    valueLabelDisplay="auto"
                />
              </Box>
            </Collapse>
        );
      case "button":
        return (
          <Button
            className="elementOptionButton"
            variant="contained"
            onClick={handleChange}
          >
            {setting.label}
          </Button>
        );
      case "colorPicker":
        return (
          <ColorPicker
            hex={defaultValue}
            label={setting.label}
            className="elementOptionInput"
            setColor={handleChange}
          />
        );
      case "toggle":
        return (
            <Box sx={{ width: "90%", }}>
              <Typography id="spacer-size" gutterBottom>
                {setting.label}
              </Typography>
              <Slider
                  name="spacer-size"
                  className="elementOptionInput"
                  onChange={handleChange}
                  min={setting.options.min}
                  max={setting.options.max}
                  value={defaultValue}
                  aria-labelledby="spacer-size"
                  valueLabelDisplay="auto"
              />
            </Box>);
      case "toggleAlignment":

        return (
            <Stack>
                <ToggleButtonGroup
                    value={defaultValue}
                    exclusive
                    onChange={handleChange}
                    aria-label="text-alignment" >
                    <ToggleButton value="left" aria-label="left aligned">
                        <FormatAlignLeftIcon pointerEvents="none"/>
                    </ToggleButton>,
                    <ToggleButton value="center" aria-label="centered">
                        <FormatAlignCenterIcon pointerEvents="none"/>
                    </ToggleButton>,
                    <ToggleButton value="right" aria-label="right aligned">
                        <FormatAlignRightIcon pointerEvents="none"/>
                    </ToggleButton>,
                </ToggleButtonGroup>
            </Stack>
        );
      default:
        return (<p>Setting type "{setting.type}" not defined!</p>);
    }
  };

  return (
    <Slide direction="right" in={isElementEditorOpen}>
      <div id="elementOptions">
        {currentElementSettings.map(section => {
          return (
            <div className="elementSettingsSection">
              <p className="sectionHeading">
                {section.name}
              </p>
              {section.description &&
              <div className="elementDescription">
                {section.description}
              </div>
              }
              {
                section.content.map(setting => {
                  return (
                    <div key={setting.id} className="elementOption">
                        {setting.description &&
                        <p className="elementOptionDescription">
                            {setting.description}
                        </p>
                        }
                      {RenderSetting(setting)}
                    </div>
                  );
                })
              }
            </div>
          );
        })}
        <Button
          size={"small"}
          className="removeElementButton"
          onClick={handleRemoveElementPopup}
        >
          Remove element <DeleteOutlineIcon fontSize={"small"}/>
        </Button>
        {isRemoveElementPopupOpen &&
        <RemoveElementPopup
          popupClose={handleRemoveElementPopup}
          currentElement={currentElement}
          currentElements={currentElements}
          setCurrentElements={setCurrentElements}
          setIsElementEditorOpen={setIsElementEditorOpen}
          isRemoveElementPopupOpen={isRemoveElementPopupOpen}
        />
        }
      </div>
    </Slide>
  );
}

export default ElementOptions;
