import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";

function ScreenshotsPopup({
  popupClose,
  isScreenshotsPopupOpen,
  allTemplates,
  setCurrentTemplate,
  setIsMakingScreenshots,
}) {

  // TODO remove the following comment when the variable below is used
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState('');
  const [templatesHtml, setTemplatesHtml] = useState([]);
  // TODO remove the following comment when the variable below is used
  // eslint-disable-next-line no-unused-vars
  const [ counter, setCounter] = useState(0);
  const html = useState(document.querySelector('#previewWrapper iframe').srcdoc);
  const exportButton = useRef(null);

  // TODO remove the following comment when the variable below is used
  // eslint-disable-next-line no-unused-vars
  const requestOptions = {
    method: 'POST',
    mode: 'no-cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ content: html })
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleExportClick = (e) => {

    window.currentTemplate = allTemplates[counter].id;
    setCurrentTemplate(allTemplates[counter].id);
    if ( document.querySelector('#previewWrapper iframe .template-' + (counter - 1)) === true ){
      document.querySelector('#previewWrapper iframe .template-' + (counter - 1)).classList.remove('template-' + (counter - 1));
    }
    document.querySelector('#previewWrapper iframe').classList.add('template-'+counter);
    fetchAPI();
  }

  const targetNode = document.querySelector('#previewWrapper iframe');




  function fetchAPI() {
    let observer = new MutationObserver(function (event) {
      setTemplatesHtml([...templatesHtml, {id: allTemplates[counter].id, html: document.querySelector('#previewWrapper iframe').srcdoc}]);
      handleExportClick();
    })

    observer.observe(targetNode, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false
    })

    if ( allTemplates.length <= counter ){
      setCounter(counter+1);
      handleExportClick();
    }

    popupClose();
    fetch('https://dev.prettyemails.outsmart.digital/api/test.php?email=' + email, requestOptions)
      .then(resp => resp.json())
      .then((result) => {
        return result;
      });
  }

  return (
    <Fade
      in={isScreenshotsPopupOpen}
      timeout={600}
      style={{ transitionDelay: '100ms' }}
    >
      <div className="popupWrapper" onClick={e => {
        if ( e.target.classList.contains('popupWrapper') ) {
          popupClose();
        }
        e.stopPropagation();
        return false;
      }}>
        <Paper
          className="popup"
        >
          <div className="popupElementsWrapper">
            <div className="popupHeading">
              <h2 style={{ marginTop: 0 }}>Export screenshots</h2>
              <CloseIcon
                onClick={popupClose}
                className="closeButton"
              />
            </div>
            <div className="popupBody">
              <div className="description">
                <p>We'll send you a ZIP file containing images of all email templates in your
                  theme, in both desktop and mobile layout.</p>
              </div>
              <p
                style={{
                  margin: 0,
                  fontWeight: 600,
                }}
              >Email address</p>
              <TextField
                onChange={handleEmailChange}
                id="email"
                label="Email"
                style={{
                  margin: "10px 0",
                  width: "100%",
                }}
              />
            </div>
            <div className="popupFooter">
              <Button
                style={{
                  color: '#3b3b3b',
                  border: '1px solid #9b9b9b',
                  margin: "10px 5px",
                }}
                onClick={popupClose}
                variant="outlined"
                size="small"
              >
                Close
              </Button>
              <Button
                style={{
                  border: '1px solid #9b9b9b',
                  margin: "10px 5px",
                }}
                variant="contained"
                size="small"
                ref={exportButton}
                onClick={() => {
                  // handleExportClick();
                  fetchAPI();
                  setIsMakingScreenshots(true);
                }}
              >
                Export screenshot
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    </Fade>
);
}

export default ScreenshotsPopup;
